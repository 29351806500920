import React from 'react';


function ErrorPage(props) {
    const pageStyle={
        "mainStyle":{
            display:"flex",
            alignItems:"center",
            justifyContent:"center",
            height:"100%",
        },
        "contentStyle":{
            textAlign:"center",
            marginTop:"20px",
        },
        "pStyle":{
            fontSize:"18px",
            marginBottom:"0px",
            fontWeight:"bold",
        },
    }
    
    return (
        <div style={pageStyle.mainStyle}>
            <div style={{textAlign:'center'}}>
                <img style={{margin:"0px auto"}} width={props.imageWidth} src={props.errorImage} />
                <div style={pageStyle.contentStyle}>
                    <p style={pageStyle.pStyle} >
                        {props.message}
                    </p>
                </div>
            </div>
        </div>

    );
}

export default ErrorPage;