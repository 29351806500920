import React, { useState } from 'react';
import {Button, Icon, TextField} from "@shopify/polaris";
import {
    CustomersMinor
  } from '@shopify/polaris-icons';
import axios from 'axios';
import { BASE_URL } from './data';
import notAuthorised from "../assets/images/404.png"
import ErrorPage from "./ErrorPage";

function Login(props) {

    const [domain,setDomain]= useState("")
    const [error,setError]=useState(false);
    const handleLoginShopify=()=>{
        const domainData={"domain":domain};
        axios({
            method:"post",
            url:BASE_URL+"api/login-with-shopify",
            data:domainData,
        }).then((response=>{
            if(response.data[0]==200)
            {
                let store_id=response.data[1];
                let url=window.location.href;
                window.history.pushState({},null,url+"?store_id="+store_id);
                window.location.reload();
            }else{
                setError(true)
            }
        }));
    };

    const error_message=(<>You are not authorised to access this application.<br/>Please install first from <a  href="https://apps.shopify.com/"><b>shopify app store</b></a>.
    <br/>If you already installed then try accessing from your shopify store <b style={{color:"#007bff"}}>Apps</b>.</>);

    return (
       <> {error?
            <ErrorPage message={error_message} errorImage={notAuthorised} imageWidth="350" />
         :<div style={{display:"flex",justifyContent:"center",paddingTop:"100px"}}>
            <div>
                <div style={{textAlign:"center"}}><img src={props.logo} /></div>
                <div style={{marginTop:"20px",borderTop:"5px solid green",padding:"40px 50px",width:"400px", backgroundColor:"white",boxShadow:"0px 0px 4px 1px rgba(153,153,153,0.5)"}}>
                    <TextField
                        placeholder="your-shop-url.myshopify.com"
                        prefix={<Icon source={CustomersMinor} color="subdued"/>}
                        value={domain}
                        onChange={setDomain}
                    />
                    <div style={{color:"green", paddingTop:"15px"}}><Button monochrome outline onClick={handleLoginShopify} fullWidth>Login with shopify</Button></div>
                </div>
            </div>
        </div>
        }</>
    );
}

export default Login;