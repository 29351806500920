import { AppProvider, Badge, ButtonGroup, Icon, Pagination, ProgressBar, RadioButton } from '@shopify/polaris';
import React, { useCallback, useState, useEffect } from 'react';

import { Card, Tabs } from '@shopify/polaris';
import { Caption, DropZone, Thumbnail, } from '@shopify/polaris';
import { InlineError, Select } from '@shopify/polaris';
import { FormLayout } from '@shopify/polaris';
import { TextField, Toast } from '@shopify/polaris';

import { ResourceList } from '@shopify/polaris';
import { ResourceItem } from '@shopify/polaris';
import { TextStyle } from '@shopify/polaris';

import { Button, Spinner } from "@shopify/polaris";
import { NoteMinor } from '@shopify/polaris-icons';
import axios from 'axios';
import { BASE_URL } from '../data';
import en from '@shopify/polaris/locales/en.json';
import {
    ChevronRightMinor
} from '@shopify/polaris-icons';


const FileDownload = require('js-file-download');

function ZipcodeImport(props) {
    /*
     Shopify label size 
   */
    const shopifyLabel = { fontSize: '14px', lineHeight: '20px', fontWeight: '400' }

    /**
 *   Zipcode Import page
 */
    const [planMsg, setPlanMsg] = useState(false);
    const togglePlanMsg = () => { setPlanMsg(!planMsg) }
    const planToastMarkup = (planMsg ? <Toast content="Please Buy Primuim to use this functionality" error onDismiss={togglePlanMsg} /> : null)
    // Tabs for import

    const [curImportTab, setImportTab] = useState(props.plan == "free" ? 1 : 0);
    // const handleImportTab = ((index) => { if (props.plan != "free") { setImportTab(index) } else { togglePlanMsg() } });
    const handleImportTab = ((index) => setImportTab(index));
    const importTabs = [
        {
            id: "bulkImport",
            content: "Bulk Import",
            panelID: "bulkImportPanel"
        },
        {
            id: "singleImport",
            content: "Single Import",
            panelID: "singleImportPanel"
        }
    ];

    //    {props.plan=="free"?<Badge status="info">Premium</Badge>:null}

    /**      
     *       Dragzone file upload
     */

    const validImageTypes = ['text/csv']
    const [curCSV, setCSV] = useState();
    const handleCSV = ((_dropFiles, acceptedFiles, _rejectedFiles) => setCSV((curCSV) => acceptedFiles[0]));
    const fileUpload = !curCSV && <DropZone.FileUpload accept="text/csv" actionTitle="Upload CSV File" actionHint="or drop to upload" />
    const uploadedFile = curCSV && (
        <div style={{ display: "flex", fontSize: "15px" }}>
            <Thumbnail
                size="small"
                alt={curCSV.name}
                source={
                    validImageTypes.indexOf(curCSV.type) > 0
                        ? window.URL.createObjectURL(curCSV)
                        : NoteMinor
                }
            />
            <div>
                {curCSV.name} <Caption>{curCSV.size} bytes</Caption>
            </div>
        </div>
    );


    /**
     *     Single zipcode import
     */
    const [curSingleZipcode, setSingleZipcode] = useState("395003,395008,395006");
    const hanleSingleZipcode = ((zipcode) => setSingleZipcode(zipcode));


    //product option


    const [product, setGetProduct] = useState([]);



    const [collections, setColllection] = useState(props.collection);

    //  const [zipcode, setZipcode] = useState([])

    // useEffect(() => {

    //    // setGetProduct(props.product)
    //    setColllection(props.collection)

    //    return () => {
    //        setGetProduct([]);     
    //        setUpdateZipcode([]);
    //    };


    // },[props.product, props.collection])






    const [selected, setSelected] = useState('');



    const handleSelectChange = useCallback((value) => setSelected(value), []);


    const [toastData, setToastData] = useState("");


    //Success
    const [activeToast, setToast] = useState(false);
    const toggleToast = (() => setToast(!activeToast));
    const toastSuccessMarkup = (activeToast ? <Toast content={toastData} onDismiss={toggleToast} /> : null);
    //Error

    const [activeErrorToast, setErrorToast] = useState(false);
    const toggleErrorToast = (() => setErrorToast(!activeErrorToast));
    const toastErrorMarkup = (activeErrorToast ? <Toast content={toastData} error onDismiss={toggleErrorToast} /> : null);

    // loading for submit button
    const [curLoadSaveSpinner, setLoadSaveSpinner] = useState(false);
    const spinnerMarkup = (<Button><Spinner accessibilityLabel="Install Loading" size="small" /></Button>);


    const [showProductPage, setShowProductPage] = useState(false)

    const options_Collection = collections

    const options = [
        { label: 'Product title', value: 'title' },
        { label: 'Product type', value: 'type' },
        { label: 'Product vendor', value: 'vendor' },
        { label: 'Product tag', value: 'tag' },
        { label: 'Product Price', value: 'variant_price' },
        { label: 'Compare at price', value: 'variant_compare_at_price' },
        { label: 'Weight', value: 'variant_weight' },
        { label: 'Inventory stock', value: 'variant_inventory' },
        { label: 'Variant’s title', value: 'variant_title' },

    ]

    //    model rules

    const [ruleOption, setRuleOption] = useState(options)
    const rules = props.rules
    const handleRuleOption = (id) => {
        let options = [];
        let curRules = rules[id]
        let setOption = ""
        var i = 0;
        for (i = 0; i < ruleOption.length; i++) {
            let curOption = ruleOption[i]
            if (!curRules.hasOwnProperty(curOption.value)) {
                curOption["disabled"] = "disabled"
            }
            else {
                if (curOption.hasOwnProperty("disabled")) {
                    delete curOption.disabled
                }
                setOption = curOption.value
            }
            options.push(curOption)
        }
        return setOption;
    }

    const [ruleSelected, setRuleSelected] = useState(ruleOption[0].value)
    const handleRuleSelectChange = useCallback((value) => setRuleSelected(value), []);








    const handelSampleCSV = ((_event) => {
        let str = "Zipcode\n504293\n504307\n504001\n504251"
        FileDownload(str, 'sample zipcode.csv')

    });


    // Product style

    const ProductSelectionStyle = {
        model: {
            position: "fixed",
            zIndex: "999",
            left: "0px",
            top: "0px",
            width: "100%",
            height: "100%",
            backgroundColor: "white",
            display: "flex",
            flexDirection: "column",
            justifyContent: "space-between",
        },
        modelHeader: {
            display: "flex",
            justifyContent: "space-between",
            fontSize: "16px",
            boxShadow: "-1px 5px 7px -3px rgba(0,0,0,0.16)",
        },
        modelTitle: {
            display: "inline-block",
            padding: "15px 20px",
            margin: "0px",
        },
        modelClose: {
            padding: "15px 20px",
            backgroundColor: "red",
            fontWeight: "bold",
            color: "white",
        },
        modelFooter: {
            display: "flex",
            justifyContent: "space-between",
            boxShadow: "-1px -6px 7px -3px rgba(0,0,0,0.16)",
            padding: "15px 20px",
        },
        modelBody: {
            height: "85%",
            width: "100%",
            display: "flex",//responsive
        },
        modelBodyProductBox: {
            width: "50%",//responsive
            borderRight: "1px solid black",
            padding: "0px 15px",
        },
        modelBodyCollectionBox: {
            width: "50%",//responsive
            borderLeft: "1px solid black",
            padding: "0px 15px",
        },
        modelBodyCollectionList: {
            height: "50%",
            borderBottom: "1px solid black",
        },
        modelBodySelectedList: {
            height: "50%",
            borderTop: "1px solid black",
        },
        modelCollectionListTitle: {
            height: "10%",
            padding: "5px 10px",
            display: "flex",
            justifyContent: "space-between",
        },
        modelProductListTitle: {
            height: "7%",
            padding: "5px 10px",
            display: "flex",
            justifyContent: "space-between",
        },
        modelSelectedListTitle: {
            height: "12%",
            padding: "4px 10px",
            display: "flex",
            justifyContent: "space-between",
        },
        setSpinner: {
            height: "100%",
            width: "100%",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
        }
    }

    //selected collection
    const [selectedCollection, setSelectedCollection] = useState()


    const handleSelectedCollection = (value) => {
        setSelectedCollection(value)
        let setOption = handleRuleOption(value);
        if (setOption != "") {
            setRuleSelected(setOption)
        }
        else {
            setRuleSelected(null)
        }
    }



    //pagination
    const [sinceId, setSinceId] = useState(null)
    const [totalPages, setTotalPages] = useState(null)
    const [curPage, setCurPage] = useState(0)

    // model loading and spinner
    const [isModelLoading, setModelLoading] = useState(false);
    const modelSpinner = (<div style={ProductSelectionStyle.setSpinner} >
        <div>
            <div style={{ paddingLeft: "5px" }}><Spinner size="large" /></div>
            <div style={{ fontSize: '14px', lineHeight: '20px', fontWeight: '400', paddingTop: "10px" }}>Loading ...</div>
        </div>
    </div>)


    const getProductList = (getPageType) => {
        if (getPageType == "start") {
            setCurPage(0)
            setSinceId(null)
            console.log("since id", sinceId)
        }
        setCurSelectedList([])
        setCurProductList([])
        setGetProduct([])
        setModelLoading(true)
        const store_id = localStorage.getItem("store_id");
        const collectionData = getPageType == "start" ? { 'collection_id': selectedCollection, 'rule': ruleSelected, 'store_id': store_id, "cur_page": getPageType == "start" ? 0 : curPage } : { 'collection_id': selectedCollection, 'rule': ruleSelected, 'store_id': store_id, "cur_page": curPage, "since_id": sinceId };


        axios({
            method: "post",
            url: BASE_URL + "api/get-product-by-collection-rule",
            data: collectionData,
        }).then((response) => {
            const data = response.data
            if (data) {
                setGetProduct(response.data.products)
                setSinceId(response.data.since_id)
                setTotalPages(response.data.total_page)
                setCurPage(response.data.cur_page)

                // console.log("Product",response.data)
                setShowProductPage(true)
            }


            setModelLoading(false)
        })
            .catch((error) => {

                setModelLoading(false)

            });


    }

    //selected product list
    const [selectedProduct, setSelectedProduct] = useState([])

    const [curSelectedList, setCurSelectedList] = useState([])

    //Bulk Import Save
    const handleProductSelectedSubmit = (() => {


        if (curCSV === undefined) {
            setToastData("Upload Zipcode CSV file.");
            toggleErrorToast();

        }
        else if (selectedProduct.length === 0) {
            setToastData("Please select Products.");
            toggleErrorToast();
        }
        else {
            let selectedProductId = [];
            selectedProduct.forEach((value) => {
                selectedProductId.push(value.id)
            })

            setLoadSaveSpinner(true);
            const store_id = localStorage.getItem("store_id");
            const uploadData = new FormData();

            uploadData.append('store_id', store_id);
            uploadData.append('productsList', selectedProductId);
            uploadData.append('zipcodeCSV', curCSV, curCSV.name);
            uploadData.append('collection_id', selectedCollection);
            uploadData.append('rule', ruleSelected);

            axios({
                method: "post",
                url: BASE_URL + "api/set-zipcode",
                data: uploadData,
            }).then((response) => {

                const data = response.data
                if (data[0] === "200") {
                    setToastData("Successfully Added.");
                    toggleToast();
                    setLoadSaveSpinner(false);

                }
                else if (data[0] === "409") {
                    setToastData("Please try with another theme resource already integrated.");
                    toggleErrorToast();
                    setLoadSaveSpinner(false);

                }
                else if (data[0] === "500") {
                    setToastData("File type must be CSV !");
                    toggleErrorToast();
                    setLoadSaveSpinner(false);

                }
                else {
                    setToastData("Resource not found, Retry from shopify.");
                    toggleErrorToast();
                    setLoadSaveSpinner(false);

                }
                // setActive((active) => !active)
            })
                .catch((error) => {
                    setToastData("Internal Server Error,Reload might solve problem.");
                    toggleErrorToast();
                    setLoadSaveSpinner(false);

                });

        }

    });

    //Single Import Save
    const handleSingleProductSelectedSubmit = (() => {


        if (curSingleZipcode === undefined || curSingleZipcode === null || curSingleZipcode === '') {

            setToastData("Enter Zipcodes.");
            toggleToast();

        }
        else if (selectedProduct.length === 0) {

            setToastData("Please select Products !!");
            toggleToast();
        }
        else {
            let selectedProductId = [];
            selectedProduct.forEach((value) => {
                selectedProductId.push(value.id)
            })
            setLoadSaveSpinner(true);
            const store_id = localStorage.getItem("store_id");
            const uploadData = new FormData();

            uploadData.append('store_id', store_id);
            uploadData.append('productsList', selectedProductId);
            uploadData.append('singleZipcode', curSingleZipcode);
            uploadData.append('collection_id', selected);
            uploadData.append('rule', ruleSelected)

            axios({
                method: "post",
                url: BASE_URL + "api/set-single-zipcode",
                data: uploadData,
            }).then((response) => {

                const data = response.data
                if (data[0] === "200") {
                    setToastData("Product Add with zipcode Successfully.");
                    toggleToast();
                    setLoadSaveSpinner(false);

                }
                else if (data[0] === "409") {
                    setToastData("Please try with another theme resource already integrated.");
                    toggleErrorToast();
                    setLoadSaveSpinner(false);

                }
                else if (data[0] === "limit exceed") {
                    setToastData("Your plan is FREE, you limit of 10 zipcode is exceed.");
                    toggleErrorToast();
                    setLoadSaveSpinner(false);
                }
                else {
                    setToastData("Resource not found, Retry from shopify.");
                    toggleErrorToast();
                    setLoadSaveSpinner(false);

                }
                // setActive((active) => !active)
            })
                .catch((error) => {
                    setToastData("Internal Server Error,Reload might solve problem.");
                    toggleErrorToast();
                    setLoadSaveSpinner(false);

                });

        }

    });

    //current product list
    const [curProductList, setCurProductList] = useState([])

    const handleSelectedList = () => {
        let selectedproduct = selectedProduct;
        let sc_new = []

        let checkAvailable = (checkValue) => {
            let check = false;
            selectedProduct.forEach(
                (value) => {
                    if (value.id == checkValue.id) {
                        check = true;
                    }
                }
            );
            return check;
        };
        product.forEach(
            (value) => {
                if (curProductList.indexOf(value.id) != -1 && !(checkAvailable(value))) {
                    selectedproduct.push(value)
                }
            }
        );

        selectedproduct.forEach((value) => {
            sc_new.push(value)
        });

        setSelectedProduct(sc_new);



    }

    //remove product from selected
    const handleRemoveSelectedProduct = () => {

        let sc = selectedProduct;
        let new_sc = [];
        sc.forEach((value) => {
            if (curSelectedList.indexOf(value.id) == -1) {
                new_sc.push(value)
            }
        });
        setSelectedProduct(new_sc);
        setCurSelectedList([])
    }


    //render product list
    const productRenderItem = (item) => {
        const { id, title, url } = item;

        const media = <img src={url} style={{ height: "35px", width: "35px" }} />

        const resource = (<ResourceItem
            id={parseInt(id)}
            media={media}

        >
            <div style={{ display: "flex", justifyContent: "space-between" }}>
                <h3 style={{ paddingTop: "5px" }}>
                    <TextStyle variation="strong">{title}</TextStyle>
                </h3>

            </div>
        </ResourceItem>);
        return resource;
    }

    const displayProductResource = (
        <AppProvider i18n={en}>
            <ResourceList
                items={product}
                renderItem={productRenderItem}
                selectedItems={curProductList}
                onSelectionChange={setCurProductList}
                selectable

            />
        </AppProvider>
    );



    //render selected list
    const selectedRenderItem = (item) => {
        const { id, title, url } = item;

        const media = <img src={url} style={{ height: "35px", width: "35px" }} />

        const resource = (<ResourceItem
            id={parseInt(id)}
            media={media}

        >
            <div style={{ display: "flex", justifyContent: "space-between" }}>
                <h3 style={{ paddingTop: "5px" }}>
                    <TextStyle variation="strong">{title}</TextStyle>
                </h3>

            </div>
        </ResourceItem>);
        return resource;
    }

    const displaySelectedResource = (
        <AppProvider i18n={en}>
            <ResourceList
                items={selectedProduct}
                renderItem={selectedRenderItem}
                selectedItems={curSelectedList}
                onSelectionChange={setCurSelectedList}
                selectable

            />
        </AppProvider>
    );

    // Product Model State
    const [modelToggle, setModelToggle] = useState(false)
    const handleModelToggle = (cmdModel) => {
        if (cmdModel == "close") {
            setSelectedCollection()
            setGetProduct([])
            setSelectedProduct([])
        }
        else if (cmdModel == "save") {
            setSelectedCollection()
            setGetProduct([])
        }
        setModelToggle(!modelToggle)
    }

    // render items for collection list
    const handelRenderCollection = (item) => {
        const { value, label, image } = item;

        const media = image != null ? <Thumbnail source={image} size="small" /> : <Thumbnail source={NoteMinor} size="small" />

        const resource = (<ResourceItem handleRemoveSelectedCollection
            id={parseInt(value)}
            media={media}
            onClick={handleSelectedCollection}
        >
            <div style={{ display: "flex", justifyContent: "space-between" }}>
                <h3 style={{ paddingTop: "5px" }}>
                    <TextStyle variation="strong">{label}</TextStyle>
                </h3>
                <RadioButton

                    id={parseInt(value)}
                    name="collection"
                    checked={selectedCollection == parseInt(value)}

                />
            </div>
        </ResourceItem>);
        return resource;
    }



    // Product Model structure

    const ProductSelectionModel = (

        <div style={ProductSelectionStyle.model}>
            <div style={ProductSelectionStyle.modelHeader}><h2 style={ProductSelectionStyle.modelTitle}>Add Zipcode to Product</h2> <span onClick={() => handleModelToggle("close")} style={ProductSelectionStyle.modelClose}>X</span></div>
            <div style={ProductSelectionStyle.modelBody}>
                {/* Product List */}
                <div style={ProductSelectionStyle.modelBodyProductBox}>
                    {/* title */}
                    <div style={ProductSelectionStyle.modelProductListTitle}>
                        <h2>Product List</h2>
                        {product.length > 0 ? <Button onClick={handleSelectedList} >Add to Selected</Button> : null}
                    </div>
                    {/* body */}
                    <div style={{ height: "88%", overflow: "auto" }}>
                        {isModelLoading ? modelSpinner : displayProductResource}
                    </div>
                    <div style={{ height: "5%", display: "flex", justifyContent: "flex-end" }}>
                        {product.length > 0 ? <Button disabled={!(curPage < totalPages)} onClick={() => getProductList("")}> <Icon source={ChevronRightMinor} /> </Button> : null}
                    </div>
                </div>
                <div style={ProductSelectionStyle.modelBodyCollectionBox}>
                    {/* Collection List */}
                    <div style={ProductSelectionStyle.modelBodyCollectionList}>
                        {/* title  */}
                        <div style={ProductSelectionStyle.modelCollectionListTitle}>
                            <h2>Collection List</h2>
                            <ButtonGroup segmented>
                                <Select
                                    label="Rules :"
                                    labelInline
                                    options={ruleOption}
                                    onChange={handleRuleSelectChange}
                                    value={ruleSelected}
                                />
                                {isModelLoading ? <Button disabled>View Products</Button> : <Button outline onClick={() => getProductList("start")}>View Products</Button>}
                            </ButtonGroup>
                        </div>
                        {/* body */}
                        <div style={{ height: "80%", overflow: "auto" }}>
                            <ResourceList
                                items={options_Collection}
                                renderItem={handelRenderCollection}
                            />
                        </div>
                    </div>
                    {/* Selected List */}
                    <div style={ProductSelectionStyle.modelBodySelectedList}>
                        {/* title */}
                        <div style={ProductSelectionStyle.modelSelectedListTitle}>
                            <h2>Selected List</h2>
                            {selectedProduct.length > 0 ? <Button onClick={handleRemoveSelectedProduct}>Remove</Button> : null}

                        </div>
                        {/* body */}
                        <div style={{ height: "88%", overflow: 'auto' }}>
                            {displaySelectedResource}
                        </div>
                    </div>
                </div>
            </div>
            <div style={ProductSelectionStyle.modelFooter}>
                <div style={{ color: "red" }}><Button onClick={() => handleModelToggle("close")} monochrome outline>Cancel</Button></div>
                <div style={{ color: "green" }}><Button monochrome onClick={() => handleModelToggle("save")} outline>Confirm</Button></div>
            </div>
        </div>

    );
    const importPage = (
        <Card title="ADD ZIPCODE TO PRODUCTS FOR FILTER" sectioned>
            <FormLayout>
                {/* Tabs for import  */}
                <div style={{ display: "flex", justifyContent: "center" }}>
                    <Card.Section>
                        <Tabs selected={curImportTab} tabs={importTabs} onSelect={handleImportTab} fitted ></Tabs>
                    </Card.Section>
                </div>
                {curImportTab === 0 ?
                    <Card.Section title="Import Type Bulk">


                        <DropZone id="csvUpload" paddingBottom allowMultiple={false} onDrop={handleCSV}>
                            {uploadedFile}
                            {fileUpload}
                        </DropZone>
                        <div style={{ display: "flex", justifyContent: "flex-end", padding: "10px 0px" }}>

                            <Button plain onClick={handelSampleCSV}>Sample zipcode CSV</Button>
                        </div>
                        <div style={{ maxHeight: "500px" }}>

                        </div>


                    </Card.Section>
                    :
                    // Single import

                    <Card.Section title="Import Type Single">

                        <TextField

                            multiline={4}
                            maxLength={100}
                            value={curSingleZipcode}
                            onChange={hanleSingleZipcode}
                        />
                        <div style={{ padding: "10px 0px" }}>
                            <InlineError message="All zipcode/postal code must be comma separated. eg: 395003,395006,395008 " />
                        </div>


                    </Card.Section>}


                <Card.Section>
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        <Button onClick={handleModelToggle} primary>Select Product for Zipcode</Button>
                        <div style={{ color: "green" }}>{curLoadSaveSpinner ? spinnerMarkup : <Button monochrome outline onClick={() => { if (curImportTab == 0) { handleProductSelectedSubmit() } else { handleSingleProductSelectedSubmit() } }} >Save</Button>}</div>
                    </div>
                </Card.Section>

                {modelToggle ? ProductSelectionModel : null}
            </FormLayout>
        </Card>
    );

    return (
        <div style={{ padding: "15px 0px" }}>
            {importPage}
            {toastSuccessMarkup}
            {toastErrorMarkup}
            {planToastMarkup}
        </div>
    );
}

export default ZipcodeImport;