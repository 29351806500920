import { DisplayText, Label, Layout } from '@shopify/polaris';
import { FormLayout,Tabs } from '@shopify/polaris';

import { Card } from '@shopify/polaris';
import { Page } from '@shopify/polaris';
import React, {  useEffect, useState } from 'react';
import {Select} from '@shopify/polaris';
import { Button } from '@shopify/polaris';
import { Icon } from '@shopify/polaris';

import {AutomationMajor,CodeMajor} from '@shopify/polaris-icons';
import { TextField,Toast,Form,Spinner} from '@shopify/polaris';
import { ThemesOptionData,BASE_URL } from './data';
import axios from 'axios';

export default function ThemeIntegrate(props) {

    const [curHTML, setValue] = useState(props.curHTML);
    const [curCSS, setCssValue] = useState(props.curCSS);
    const [curJS, setJsValue] = useState(props.curJS);
    const [getThemesData,setThemesData]=useState(props.getThemesData);

    const [curTheme,setTheme]=useState(props.getThemesData[0].value);
    const handleTheme=((theme)=>{setTheme(theme) });
  
    const [curManTheme,setManTheme]=useState(props.getThemesData[0].value);
    const handleManTheme=((theme)=>setManTheme(theme));


    useEffect(()=>{ 
    
            setValue(props.curHTML);
            setCssValue(props.curCSS);
            setJsValue(props.curJS);
            setThemesData(props.getThemesData)
            setTheme(props.getThemesData[0].value)
            console.log(props.getThemesData[0]);
            

            return () => {
                setValue(); 
                setCssValue();

                setJsValue(); 
                setThemesData();

                setTheme(); 
                setManTheme({});
            };
            
    },[props.curHTML, props.curCSS, props.curJS, props.getThemesData]);
   
   
    /*
      Shopify label size 
    */ 
    const shopifyLabel={fontSize:'14px',lineHeight:'20px',fontWeight:'400'}

    /**
     *  Toast Message
     */
    
    const [toastData,setToastData]=useState("");
    //Success
    const [activeToast,setToast]=useState(false);
    const toggleToast=(()=>setToast(!activeToast));
    const toastSuccessMarkup=(activeToast?<Toast content={toastData} onDismiss={toggleToast}  />:null);
    //Error

    const [activeErrorToast,setErrorToast]=useState(false);
    const toggleErrorToast=(()=>setErrorToast(!activeErrorToast));
    const toastErrorMarkup=(activeErrorToast?<Toast content={toastData} error onDismiss={toggleErrorToast}  />:null);

    /**
     *    Automatic  Integrate Theme options
     */

  
    // loading for submit button
    const [curLoadInstallSpinner,setLoadInstallSpinner]=useState(false);
    const [curLoadUninstallSpinner,setLoadUninstallSpinner]=useState(false);
    const spinnerMarkup=(<Button><Spinner accessibilityLabel="Install Loading" size="small" /></Button>);

    const handleAutoThemeSubmit=((_event)=>{
        setLoadInstallSpinner(true);
        if(curTheme!=="selectValue" && curTheme!==undefined)
        {
            const store_id=localStorage.getItem("store_id");
            const theme_data = {'theme_id' : curTheme, 'store_id' : store_id,'install_type':'automatic'};
            axios({
                method:"post",
                url:BASE_URL+"api/get-themes",
                data:theme_data,
            }).then((response)=>{
                console.log(response.data);
                const data=response.data
                if(data[0]==="200")
                {
                    ThemesOptionData().then(result=>typeof(result?.data[0])!="string"?setThemesData(result.data):null);
                    setToastData("Theme Integrated Successfully.");
                    toggleToast();
                    setLoadInstallSpinner(false);
                }
                else if(data[0]==="409") 
                {
                    setToastData("Please try with another theme resource already integrated.");
                    toggleErrorToast();
                    setLoadInstallSpinner(false);
                }
                else{
                    setToastData("Resource not found, Retry from shopify.");
                    toggleErrorToast();
                    setLoadInstallSpinner(false);
                }
            })
            .catch((error)=>{
                setToastData("Internal Server Error,Reload might solve problem.");
                toggleErrorToast();
                setLoadInstallSpinner(false);
            });
        }
        else{
            setToastData("Please select theme.");
            toggleErrorToast();
            setLoadInstallSpinner(false);
        }
    });

    const handleAutoThemeUninstall=()=>{
        setLoadUninstallSpinner(true);
        if(curTheme!=="selectValue" && curTheme!==undefined)
        {
            
            const store_id=localStorage.getItem("store_id");
            const theme_data = {'theme_id' : curTheme, 'store_id' : store_id,'install_type':'automatic'};
            axios({
                method:"post",
                url:BASE_URL+"api/uninstall-themes",
                data:theme_data,
            }).then((response)=>{
                console.log(response.data);
                const data = response.data
                if(data[0]==="200")
                {
                    ThemesOptionData().then(result=>typeof(result?.data[0])!="string"?setThemesData(result.data):null);
                    setToastData("Theme Disintegrated Successfully.");
                    toggleToast();
                    setLoadUninstallSpinner(false);

                }
                else if (data[0]==="405"){ 
                    setToastData("Here theme not installed!");
                    toggleErrorToast();
                    setLoadUninstallSpinner(false)

                }
                else{
                    
                    setToastData("Resource not found");
                    toggleErrorToast();
                    setLoadUninstallSpinner(false);
                }
            })
            .catch((error)=>{
                setToastData("Internal Server Error, Reload might solve problem.");
                toggleErrorToast();
                setLoadUninstallSpinner(false);
            });
        }
        else{
            setToastData("Please select theme.");
            toggleErrorToast();
            setLoadUninstallSpinner(false);
        }
    };

    console.log("cur theme", curTheme)

    const automaticIntegrateForm=(
        <Form onSubmit={handleAutoThemeSubmit}>
            <FormLayout>

                {/* Integrate Theme Options */}

                <Select 
                    key="thme"
                    label="Integrate with : "
                    labelInline
                    value={curTheme}
                    onChange={handleTheme}
                    options={getThemesData?getThemesData:null}
                />

                
                {getThemesData.some((ele)=>curTheme==parseInt(ele.value)?ele.label.match(/\(manually\)/)!="(manually)":false)
                ?           

                    <>{getThemesData.some((ele)=>curTheme==parseInt(ele.value)?ele.label.match(/\(Integrated\)/)=="(Integrated)":false)
                    ? 
                        <div style={{display:"flex", justifyContent:'space-between'}}>
                            {curLoadUninstallSpinner?spinnerMarkup:<div style={{color:"red"}}><Button monochrome outline onClick={handleAutoThemeUninstall} >Disintegrate</Button> </div>} <Button >Integrate</Button>
                        </div>
                    :
                        <div style={{display:"flex", justifyContent:'space-between'}}>
                            <Button >Disintegrate</Button> {!curLoadInstallSpinner?<div style={{color:"green"}}><Button submit monochrome outline >Integrate</Button> </div>:spinnerMarkup}
                        </div>
                    }</>
                :
                    <div style={{display:"flex", justifyContent:'space-between'}}>
                        <Button monochrome outline>Disintegrate</Button>
                        <Button >Integrate</Button>
                    </div>
                }
                   
                
            </FormLayout>
        </Form>
    );

    /**
     *      Manually Integrate Theme options
     */

    


 
    const handleManuallyThemeSubmit=((_event)=>{
        const store_id=localStorage.getItem("store_id");
        const theme_data = {'theme_id' : curManTheme, 'store_id' : store_id,'install_type':'manually'};
        setLoadInstallSpinner(true);
        axios({
            method:"post",
            url:BASE_URL+"api/get-themes",
            data:theme_data,
            }).then((response)=>{
                
                const data=response.data
                if(data[0]==="200")
                {
                    ThemesOptionData().then(result=>typeof(result?.data[0])!="string"?setThemesData(result.data):null);
                    setToastData("Theme Integrated Successfully.");
                    toggleToast();
                    setLoadInstallSpinner(false);
                }
                else if(data[0]==="201")
                {
                    setToastData("Please select any theme");
                    toggleErrorToast();
                    setLoadInstallSpinner(false);
    
                }
                else
                {
                    setToastData("Resource not found, Retry from shopify.");
                    toggleErrorToast();
                    setLoadInstallSpinner(false);
                }
            })
        .catch((error)=>{
                setToastData("Internal Server Error,Reload might solve problem.");
                toggleErrorToast();
                setLoadInstallSpinner(false);
            });
    });

    const handlePayment = ((_event)=> {

        const store_id=localStorage.getItem("store_id");
        const payment_data = {'store_id' : store_id};
        axios({
            method:"post",
            url:BASE_URL+"api/payment",
            data:payment_data,
            }).then((response)=>{
                const data=response.data

                console.log(data)
                window.location.assign(data);
               
            })
        .catch((error)=>{
                
            });

    });



    const handleManuallyThemeUninstall=((_event)=>{

    
        const store_id=localStorage.getItem("store_id");
        const theme_data = {'theme_id' : curManTheme, 'store_id' : store_id,'install_type':'manually'};
        setLoadUninstallSpinner(true);
        axios({
            method:"post",
            url:BASE_URL+"api/uninstall-themes",
            data:theme_data,
            }).then((response)=>{
                const data=response.data

               
                if(data[0]==="200")
                {
                    ThemesOptionData().then(result=>typeof(result?.data[0])!="string"?setThemesData(result.data):null);
                    setToastData("Theme Integrated Successfully.");
                    toggleToast();
                    setLoadUninstallSpinner(false);

                }
                else if(data[0]==="201")
                {
                    setToastData("Please select any theme");
                    toggleErrorToast();
                    setLoadUninstallSpinner(false)
    
                }
                else if (data[0]==="405"){ 
                    setToastData("Here theme not installed!");
                    toggleErrorToast();
                    setLoadUninstallSpinner(false)

                }
                else
                {
                    setToastData("Resource not found, Retry from shopify.");
                    toggleErrorToast();
                    setLoadUninstallSpinner(false);
                }
                
            })
        .catch((error)=>{
                setToastData("Internal Server Error,Reload might solve problem.");
                toggleErrorToast();
                setLoadUninstallSpinner(false);
            });

    
    });


 
    const manualIntegrateForm=(
        <Form onSubmit={handleManuallyThemeSubmit}>
            <FormLayout>

                <div style={shopifyLabel}>HTML Snippet</div>
                <pre style={{maxHeight:"300px",overflow:"auto"}}>
                {/* Html box */}
                {curHTML}
                </pre>

                <div style={shopifyLabel}>CSS Snippet</div>
                <pre style={{maxHeight:"300px",overflow:"auto"}}>
                {/*  Css Box */}
                {curCSS}
                </pre>

                <div style={shopifyLabel}>JS Snippet</div>
                <pre style={{maxHeight:"300px",overflow:"auto"}}>
                {/* JS Box */}
                {curJS}
                </pre>
                {/* Integrate Theme Options */}
                <Select
                    key={1}
                    label="Integrate with : "
                    labelInline
                    value={curManTheme}
                    onChange={handleManTheme}
                    options={getThemesData?getThemesData:null}
                />
                <div style={{justifyContent:'space-between'}}>
                {getThemesData.some((ele)=>curManTheme==parseInt(ele.value)?ele.label.match(/\(automatic\)/)!="(automatic)":false)
                ?
                    
                    <>{getThemesData.some((ele)=>curManTheme==parseInt(ele.value)?ele.label.match(/\(Integrated\)/)=="(Integrated)":false)
                    ? 
                        <div style={{display:"flex", justifyContent:'space-between'}}>
                            {curLoadUninstallSpinner?spinnerMarkup:<div style={{color:"red"}}><Button monochrome outline onClick={handleManuallyThemeUninstall} >Disintegrate</Button> </div>} <Button >Integrate</Button>
                        </div>
                    :
                        <div style={{display:"flex", justifyContent:'space-between'}}>
                            <Button >Disintegrate</Button> {!curLoadInstallSpinner?<div style={{color:"green"}}><Button submit monochrome outline >Integrate</Button> </div>:spinnerMarkup}
                        </div>
                    }</>


                :
                    <div style={{display:"flex", justifyContent:'space-between'}}>
                        <Button monochrome outline>Disintegrate</Button>
                        <Button >Integrate</Button>
                    </div>
                }


                </div>
                
               
            </FormLayout>
        </Form>
    );
    
    /**
     *     Integrate Tabs
     */
    
    const [curIntegrateTab, setIntegrateTab]=useState(0);
    const handleIntegrateTab=((index)=>setIntegrateTab(index));
    const integrateTabs=[ 
    {
      id:"automaticIntegrate",
      content:(<Icon source={AutomationMajor}></Icon>),
      panelID:"automaticIntegratePanel"
    },
    {
      id:"manualIntegrate",
      content:(<Icon source={CodeMajor}></Icon>),
      panelID:"manualIntegratePanel"
    }
  ];

  const [viewPlan,setViewPlan] =useState(false);

  /**
   *     Language Selector
   */

    // const languageOptions=[
    //     {label:'English',value:'english'},
    //     {label:'Spanish',value:'spanish'},
    //     {label:'German',value:'german'},
    // ];

    // const [curLanguage, setLanguage] = useState(languageOptions[0].value);
    // const handleLanguage=((lang)=>setLanguage(lang));

    return (
       <Page title="Theme Integration" separator>
           <Layout>
               <Layout.AnnotatedSection title="Integrate App" description="Users have two options either user can integrate application automatic by selecting theme or user can add snippets manually inside their theme and also provide own customisation.">
                    <Card sectioned >

                        {/* Integrate Tabs */}
                        <div style={{display:"flex",justifyContent:"center"}}>
                              <Card.Section>
                                <Tabs tabs={integrateTabs} selected={curIntegrateTab} onSelect={handleIntegrateTab}  fitted >
                                </Tabs>
                              </Card.Section>
                        </div>

                        {curIntegrateTab===0?automaticIntegrateForm:manualIntegrateForm}

                        
                    </Card>
                </Layout.AnnotatedSection>
                {/* <Layout.AnnotatedSection title="Payment" description="Customer can pay one time and take benefits of our application for lifetime.">
                    <Card sectioned>
                    <FormLayout> 
                        
                        <div style={{display:'flex',justifyContent:'space-between'}}>
                            <div style={{display:"flex"}}>
                            <DisplayText size="small">{props.plan==="paid"?"Premium":"Free"}</DisplayText>
                            <div style={{marginLeft:"15px",marginTop:"5px"}}><Button onClick={()=>setViewPlan(!viewPlan)} plain>View details</Button></div>
                            </div>
                            {props.plan==="paid"
                            ?<Button>Paid</Button>
                            :<div style={{color:"green"}}><Button monochrome outline onClick={handlePayment}>BUY NOW</Button></div>
                            }
                        </div>
                    </FormLayout>
                    </Card>
                </Layout.AnnotatedSection> */}
                {viewPlan?
                <Layout.AnnotatedSection title="Plans" description="You have variety of functionality in both plans.">
                        <Card sectioned>
                            <div style={{display:"flex", justifyContent:"space-between"}}>
                                
                                
                                    <div style={{padding:"5px 20px"}}>
                                        <div style={{padding:"15px 0px",fontSize:"30px",fontWeight:"bold"}}>Free</div>
                                        <h5 style={{textTransform:"uppercase"}}>best for testing</h5>
                                        <div style={{padding:"15px 0px 0px 0px"}}>{props.plan=="free"?<Button>Using Free</Button>:<Button>Using Premium</Button>}</div>
                                        <h2 style={{padding:"15px 0px 0px 0px",fontWeight:"bold"}} >$0/- No Chargers</h2>
                                        <ul style={{fontSize:"14px",paddingTop:"15px",paddingLeft:"15px",lineHeight:"3"}}>
                                            <li style={{fontWeight:"bold",fontSize:"12px"}}>Installation with Multiple Themes</li>
                                            <li style={{fontWeight:"bold",fontSize:"12px"}}>Style Customiser For Multiple Themes</li>
                                            <li style={{fontWeight:"bold",fontSize:"12px"}}>Automatic or Manual Installation</li>
                                            <li style={{fontWeight:"bold",fontSize:"12px"}}>Single Zipcode Import</li>
                                            <li style={{fontWeight:"bold",fontSize:"12px"}}>Update or Remove Zipcode</li>
                                            <li style={{fontWeight:"bold",fontSize:"12px"}}>Downloaded all Uploaded Zipcode</li>
                                        </ul>
                                    </div>
                            
                                
                            
                                    <div style={{padding:"5px 20px"}}>
                                        <div style={{padding:"15px 0px",fontSize:"30px",fontWeight:"bold"}}>Premium</div>
                                        <h5 style={{textTransform:"uppercase",color:"red"}}>one time charge only</h5>
                                        <div style={{padding:"15px 0px 0px 0px"}}>{props.plan=="free"?<div style={{color:"green"}}><Button monochrome outline onClick={handlePayment}>Get Premium</Button></div>:<Button>Using Premium</Button>}</div>
                                        <h2 style={{padding:"15px 0px 0px 0px",fontWeight:"bold"}}>$50/- USD Only</h2>
                                        <ul style={{fontSize:"14px",paddingTop:"15px",paddingLeft:"15px",lineHeight:"3"}}>
                                            <li style={{fontWeight:"bold",fontSize:"12px"}}>Installation with Multiple Themes</li>
                                            <li style={{fontWeight:"bold",fontSize:"12px"}}>Style Customiser For Multiple Themes</li>
                                            <li style={{fontWeight:"bold",fontSize:"12px"}}>Automatic or Manual Installation</li>
                                            <li style={{fontWeight:"bold",fontSize:"12px"}}>Single Zipcode Import</li>
                                            <li style={{fontWeight:"bold",fontSize:"12px"}}>Bulk Zipcode Import</li>
                                            <li style={{fontWeight:"bold",fontSize:"12px"}}>Update or Remove Zipcode</li>
                                            <li style={{fontWeight:"bold",fontSize:"12px"}}>Downloaded all Uploaded Zipcode</li>
                                        </ul>
                                    </div>
                                
                                
                            </div>
                        </Card>
                </Layout.AnnotatedSection>
                :null}
            </Layout>
            {toastSuccessMarkup}
            {toastErrorMarkup}
       </Page>
    );
}

// export default ThemeIntegrate;