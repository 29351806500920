import React from 'react';
import {SkeletonPage} from '@shopify/polaris';
import { Loading,Frame,Page } from '@shopify/polaris';
import { Card ,FooterHelp,Link } from '@shopify/polaris';
import { SkeletonBodyText } from '@shopify/polaris';
import { SkeletonDisplayText,TextContainer,Layout } from '@shopify/polaris';

export function LoadingPage(props) {

    const loadingStyle={
        "topbar":{
            backgroundColor:"white",
            height:"60px",
            boxShadow:"0px 0px 6px 0px rgba(50, 50, 50, 0.75)",
        }

    };
    return (
        <div style={{width:"100vw",height:"100vh" , display:"flex",justifyContent:"center",alignItems:"center"}}>
                <img src={props.logo} />
        </div>
    );
}





export function LoadThemeIntegration() {

    
    return (
        <div>
                
                    <Loading />
                    {/* <div style={loadingStyle.topbar}></div> */}
                    <Page title="Theme Integration" separator >
                        <Layout>
                            <Layout.AnnotatedSection title="Integrate App" description="Users have two options either user can integrate application automatic by selecting theme or user can add snippets manually inside their theme and also provide own customisation.">
                        
                                <Card sectioned>
                                    <TextContainer>
                                        <SkeletonDisplayText size="small" />
                                        <SkeletonBodyText />
                                    </TextContainer>
                                    
                                </Card>
                            </Layout.AnnotatedSection>
                            <Layout.AnnotatedSection title="Payment" description="Zipcode/Postal code filter will be visible to user in selected payment">
                                    <Card sectioned>
                                        <SkeletonBodyText  />
                                    </Card>
                            </Layout.AnnotatedSection>
                        </Layout>
                        
                        </Page>
                
        </div>
    );
}

export function LoadCustomiser() {

    
    return (
        <div>
                
                    <Loading />
                    {/* <div style={loadingStyle.topbar}></div> */}
                    <Page title="Theme Customiser" fullWidth separator >
                        <Layout>
                            <Layout.Section >
                        
                                <Card sectioned title="Preview of Zipcode Filter">
                                
                                
                                <SkeletonBodyText lines={6} />

                                  
                                </Card>
                            </Layout.Section>
                            <Layout.Section secondary >
                                    <Card sectioned>
                                        <SkeletonBodyText  />
                                    </Card>
                            </Layout.Section>
                        </Layout>
                       
                        </Page>
                
        </div>
    );
}

export function LoadImportExport() {

    
    return (
        <div>
                
                    <Loading />
                    {/* <div style={loadingStyle.topbar}></div> */}
                    <Page title="Import & Export" separator >
                        <Layout>
                            <Layout.Section >
                        
                                <Card sectioned >
                                
                                
                                <SkeletonBodyText lines={6} />

                                  
                                </Card>
                            </Layout.Section>
                            
                        </Layout>
                        
                        </Page>
                
        </div>
    );
}

export function LoadContactUs() {

    
    return (
        <div>
                
                    <Loading />
                    {/* <div style={loadingStyle.topbar}></div> */}
                    <Page title="Contact Us" separator >
                        <Layout>
                            <Layout.AnnotatedSection title="Support" description="We are here to help!, Send us your problem regarding our app our support team will reach you to provided Email.">
                        
                                <Card sectioned>
                                    <TextContainer>
                                        <SkeletonDisplayText size="small" />
                                        <SkeletonBodyText />
                                    </TextContainer>
                                    
                                </Card>
                            </Layout.AnnotatedSection>
                            
                        </Layout>
                        
                        </Page>
                
        </div>
    );
}