
import React ,{ useState,useEffect, useCallback} from 'react';

import { Card, Thumbnail, Toast, TopBar} from '@shopify/polaris';
import { Stack, } from '@shopify/polaris';

import {FormLayout } from '@shopify/polaris';

import notFoundImage from '../../assets/images/404.png';

import { ResourceList } from '@shopify/polaris';
import { ResourceItem, RadioButton } from '@shopify/polaris';
import { TextStyle, SkeletonBodyText,SkeletonDisplayText,TextContainer } from '@shopify/polaris';

import {Button, Tag } from "@shopify/polaris";
import axios from 'axios';
import {
    NoteMinor
  } from '@shopify/polaris-icons';
import { getZipcodeUpdate,BASE_URL } from '../data';
function ZipcodeRemove(props) {
     /*
      Shopify label size 
    */ 

    const shopifyLabel={fontSize:'14px',lineHeight:'20px',fontWeight:'400'}
      
    const [searchCollectionValue, setSearchCollectionValue] = useState('');

    const [searchProductValue, setSearchProductValue] = useState('');

    const [searchZipcodeValue, setSearchZipcodeValue] = useState('');
    const [updateZipcode, setUpdateZipcode] = useState([]);

    const [collectionProduct, setCollectionProduct] = useState(null)
    const [zipcode, setZipcode]= useState([])
    const [pageState, setPageState] = useState(1)

    const [collections, setCollection] = useState([]);
    
    // Toast Markup
    const [toastMsg,setToastMsg]=useState("");
    
    //success
    const [successToast,setSuccessToast]=useState(false)
    const [errorToast,setErrorToast]=useState(false)

    const toastSuccessMarkup=(successToast?<Toast content={toastMsg} onDismiss={()=>setSuccessToast(false)}  />:null);
    const toastErrorMarkup=(errorToast?<Toast content={toastMsg} error onDismiss={()=>setErrorToast(false)}  />:null)

    useEffect(() => {
       
       
       setCollection(props.collection)
       getZipcodeUpdate().then(result => { setUpdateZipcode(result.data)})

        return () => {
            setCollection([]);
            setUpdateZipcode([]);
        };

       
    },[])
      
    
    const [selectedCollection,setSelectedCollection] =useState(null)

    const [selectedProduct, setSelectedProduct] = useState(null)

    const handleSearchChange = useCallback((value) => {
     
        setSearchCollectionValue(value)

        var libraries = collections,
        searchString = searchCollectionValue.trim().toLowerCase();
        if(searchString.length > 0 && value.length !=0){

            // We are searching. Filter the results.

            libraries = collections.filter(function(l){
                return l.label.toLowerCase().match( searchString );
            });

            setCollection(libraries)
        }
        else{
            setCollection(props.collection)
        }
    });




    const handleProductSearchChange = useCallback((value) => {
     
        setSearchProductValue(value)

        var libraries = collectionProduct,
        searchString = searchProductValue.trim().toLowerCase();
        console.log(value)
        if(searchString.length > 0 && value.length !=0){

            // We are searching. Filter the results.

            libraries = collectionProduct.filter(function(l){
                return l.title.toLowerCase().match( searchString );
            });

            setCollectionProduct(libraries)
        }
        else{

            const store_id = localStorage.getItem("store_id");
            var request = {
                params: {
                  'store_id': store_id,
                  'collection_id': selectedCollection
                }
            }

        axios.get(BASE_URL+'api/get-product-by-collection', request).then(resp => {

            console.log(resp.data);
            setCollectionProduct(resp.data)
        });

            // setCollectionProduct(collectionProduct)
        }
    });

     const handleZipcodeSearchChange = useCallback((value) => {

        console.log(value)
     
        setSearchZipcodeValue(value)

        var libraries = zipcode,
        searchString = searchZipcodeValue.trim().toLowerCase();
        if(searchString.length > 0 && value.length !=0){

            // We are searching. Filter the results.

            libraries = zipcode.filter(function(l){
                return l.toLowerCase().match( searchString );
            });

            setZipcode(libraries)
        }
        else{
            // setZipcode(zipcode)
            const store_id = localStorage.getItem("store_id");
            console.log(selectedProduct)
            var request = {
                params: {
                  'store_id': store_id,
                  'product_id': selectedProduct
                }
            }

            axios.get(BASE_URL+'api/get-zipcode-by-product', request).then(resp => {

                console.log(resp.data);
                setZipcode(resp.data)
            });

        }
    });


    
    const handleSelectedCollection = (value)=> { 
        console.log(value)
        setSelectedCollection(value)
      
    }
    
    const handleSelectedProduct = (value)=> { 
       
        setSelectedProduct(value)
        console.log(" in handel method Product---->", selectedProduct);
    }
    
    const renderColllection=(item)=>{
    
        
    
        const {value,label,image }= item;
        const media = image!=null?<Thumbnail source={image} size="small" />:<Thumbnail source={NoteMinor} size="small" />;
        return (
                <ResourceItem
                    id={value}
                    name={label}
                    media={media}
                    onClick={handleSelectedCollection}
                >
                  <div style={{display:"flex",justifyContent:"space-between"}}>
                    <h3 style={{paddingTop:"5px"}}>
                        <TextStyle variation="strong">{label}</TextStyle>
                    </h3>
                    <RadioButton
    
                        id={parseInt(value)}
                        name="collection"
                        checked={selectedCollection==parseInt(value)}
                        
                    />
                </div>
                </ResourceItem>
        );
    }
   // get product from backed

    const handleProductPage = ((_event)=> {
        
        setZipcode([])
        const store_id = localStorage.getItem("store_id");
        var request = {
            params: {
              'store_id': store_id,
              'collection_id': selectedCollection
            }
        }

        axios.get(BASE_URL+'api/get-product-by-collection', request).then(resp => {

            console.log(resp.data);
            setCollectionProduct(resp.data)
        });

        setPageState(2)

      
    });

    const renderProduct=(item)=>{
            
        
        const {id,title,url }= item;
        const media = url!=null?<Thumbnail source={url} size="small" />:<Thumbnail source={NoteMinor} size="small" />;
        return (
                <ResourceItem
                    id={id}
                    name={title}
                    media={media}
                    onClick={handleSelectedProduct}
                >
                  <div style={{display:"flex",justifyContent:"space-between"}}>
                    <h3 style={{paddingTop:"5px"}}>
                        <TextStyle variation="strong">{title}</TextStyle>
                    </h3>
                    <RadioButton
    
                        id={parseInt(id)}
                        name="Products"
                        checked={selectedProduct==parseInt(id)}
                        
                    />
                </div>
                </ResourceItem>
        );
    }


    const handleCollectionPage = ((_event)=> {
        setCollectionProduct([])
        setPageState(1)
  
    });



    const removeTag = useCallback(
        (tag, product_id) => () => {
          
            const store_id=localStorage.getItem("store_id");
            const zipcodeData = {'product_id' : product_id, 'zipcode' : tag, 'store_id':store_id};
            
            axios({
                method:"post",
                url:BASE_URL+"api/update-zipcode",
                data: zipcodeData,
            }).then((response)=>{
               if(response)
               {
                    setZipcode((previousTags) =>
                        previousTags.filter((previousTag) => previousTag !== tag),
            
                        
                    );
                    console.log(response)
               }
              
            })
            .catch((error)=>{
               
            });
            

        },
        [],
      );
    
      const tagMarkup = zipcode.map((option) => (
        <Tag key={option} onRemove={removeTag(option, selectedProduct)}>
          {option}
        </Tag>
      ));
    
    const handelRemoveAllZipcode = ((_event) =>{
        
        const store_id=localStorage.getItem("store_id");
        const zipcodeData = {'product_id' : selectedProduct, 'zipcode' : zipcode, 'store_id':store_id};

            axios({
                method:"post",
                url:BASE_URL+"api/remove-all-zipcode",
                data: zipcodeData,
            }).then((response)=>{
            if(response)
            {
                setZipcode([]);
                   
            }
            
            })
            .catch((error)=>{
            
            });

    });


    const loadinglist=(<TextContainer>
        
        <SkeletonBodyText />
    </TextContainer>);

    //not found zipcode
    const notFoundZipcodeMarkup=(<div style={{textAlign:"center" , width:'100%'}}>
        <img src={notFoundImage} width="400" />
        <h5 style={{fontWeight:"bold"}}>Zipcode is not available in this product.</h5>
        </div>);

    // show zipcode
    const zipcodePage= (
        <Card sectioned>
           <FormLayout>
               
                {/* Zipcode Page Called */}
                {/* Tabs for update zipcode  */}
                <div style={{display:"flex",justifyContent:"center",marginBottom:"15px"}}>
                    <TopBar.SearchField
                        onChange={handleZipcodeSearchChange}
                        value={searchZipcodeValue}                           
                        placeholder="Search"

                        style={{width:'100%', maxWidth:'100% !important', bottom:'200px'}}
                        showFocusBorder
                        />
                </div>
                {zipcode.length>0?<Stack spacing="tight">{tagMarkup}</Stack>:notFoundZipcodeMarkup}
                
                <div style={{display:"flex", justifyContent:'space-between'}}>
                
                    <Button plain onClick={handleProductPage}>Back to Products</Button>      
                    {zipcode.length>0?<Button plain destructive onClick= { handelRemoveAllZipcode }> Remove All</Button>:null} 
                </div>   
            </FormLayout>
         </Card>
    );

    const handleZipcodePage = ((_event)=> {

        const store_id = localStorage.getItem("store_id");
        console.log(selectedProduct)
        var request = {
            params: {
              'store_id': store_id,
              'product_id': selectedProduct
            }
        }

        axios.get(BASE_URL+'api/get-zipcode-by-product', request).then(resp => {

            console.log(resp.data);
            setZipcode(resp.data)
        });

        
        setSelectedProduct(selectedProduct)
        setPageState(3)
      
    });
    

    console.log("products",collectionProduct)
    // show product


    //not found zipcode
    const notFoundProductMarkup=(<div style={{textAlign:"center" , width:'100%'}}>
        <img src={notFoundImage} width="400" />
        <h5 style={{fontWeight:"bold"}}>Zipcode not found in any product of the collection.</h5>
        </div>);

  
    const prodcutPage= (

        <Card sectioned>
           <FormLayout>
              
                {/* Tabs for update zipcode  */}
                    <Card.Section>
                    <div style={{display:"flex",justifyContent:"center",marginBottom:"15px"}}>   
                        <TopBar.SearchField
                            onChange={handleProductSearchChange}
                            value={searchProductValue}                             
                            placeholder="Search"

                            style={{width:'100%', maxWidth:'100% !important', bottom:'200px'}}
                            showFocusBorder
                            />
                    </div> 
                       {collectionProduct!=null? <ResourceList
                            resourceName={{ singular: "Product", plural: "Products" }}
                            items={collectionProduct} 
                            renderItem={renderProduct}
                        />:loadinglist} 
                        {collectionProduct!=null?collectionProduct.length<=0?notFoundProductMarkup:null:null}
                    </Card.Section>   

                    <div style={{display:"flex", justifyContent:'space-between'}}>
                        <Button plain onClick={ handleCollectionPage }>Back to Collection</Button>
                        <Button plain onClick={selectedProduct!=null? handleZipcodePage:()=>{
                            setToastMsg("Please Select Product.");
                            setErrorToast(true);
                        }}>View Zipcodes</Button>
                    </div>


                  
            </FormLayout>
         </Card>
    );


  
    // show collections 
    
    const updatePage=(
        <Card sectioned>
           <FormLayout>
                {/* Tabs for update zipcode  */}
                
                    <Card.Section>   

                        <div style={{display:"flex",justifyContent:"center",marginBottom:"15px"}}>
                        <TopBar.SearchField
                            onChange={handleSearchChange}
                            value={searchCollectionValue}                           
                            placeholder="Search"

                            style={{width:'100%', maxWidth:'100% !important', bottom:'200px'}}
                            showFocusBorder
                            />
                        </div>
                        <ResourceList
                            resourceName={{ singular: "collection", plural: "Collections" }}
                            items={collections} 
                            renderItem={renderColllection}
                        /> 
                   
                    </Card.Section>   
                    <div style={{display:'flex', justifyContent:'flex-end', paddingRight: '30px'}}>
                        <Button plain onClick={selectedCollection!=null? handleProductPage:()=>{
                            setToastMsg("Please Select Collection.");
                            setErrorToast(true);
                        } }>View Products</Button>           
                    </div>   
            </FormLayout>
         </Card>
    );

    return (
        <div style={{ padding:"15px 0px" }}>
            {pageState===1? updatePage : pageState===2?prodcutPage: pageState===3?zipcodePage:updatePage}
            {toastSuccessMarkup}
            {toastErrorMarkup}
        </div>
    );
}

export default ZipcodeRemove;