import React, { useState, useEffect } from 'react';
import { Layout, TextContainer } from '@shopify/polaris';
import { Page } from '@shopify/polaris';
import {Card,List,Banner} from '@shopify/polaris';
import sc1 from "../assets/images/sc1.png";
import sc2 from "../assets/images/sc2.png";
import sc3 from "../assets/images/sc3.png";
import sc4 from "../assets/images/sc4.png";
import evideo from "../assets/videos/evideo.mp4";

function ContactUsPage() {

    const documentStyle={
        textStyle:{
            fontSize:"14px",
            lineHeight:"2"
        },
        bannerTextStyle:{
            fontSize:"14px" 
        },
        imgStyle:{
            maxWidth:"800px",
            padding:"15px 0px"
        }
    }

    return (
    <Page title="Documentation" separator>
            <Layout.Section >
                <Card title="Introduction" sectioned>
                    <p style={documentStyle.textStyle}>Zipster App is very useful app for those merchants who just want to provide product available for customer’s zipcode/postal code.We have the optimal solution for merchants over Shopify seeking for Zipster functionality in their store. The Zipster can be work in both way automatic and manually both have their own advantages and limitation. We are going to discribe uses and how to work with our app through the document.</p>
                </Card>
                <Card title="Scope" sectioned>
                    <p style={documentStyle.textStyle}>Merchants can use Zipster App for filtering product in home page and product list page on their storefront. Zipster App can be installed in two ways and each way overcomes another’s limitations. Automatic install has a default template, but fully customizable and in Mannual install developer can customise designs and structure of outlook on storefront.</p>
                </Card>
                <Card title="Requirements" sectioned>
                    <List type="bullet">
                        <List.Item><span style={documentStyle.textStyle}>Desktop with minimum 991x850 resolution.</span></List.Item>
                    </List>
                    <TextContainer>
                        <Banner >
                            <p style={documentStyle.bannerTextStyle}>Above requirement is only to access admin side and the storefront has no concern with resolution.</p>
                        </Banner>
                    </TextContainer>
                </Card>
                <Card title="How can a non-technical person use Zipster App ?" sectioned>
                    <p style={documentStyle.textStyle}>Zipster App has a user friendly interface , so any person with or without technical skills can use this app. Merchants can just make Zipster functionality by following steps :</p>
                    <List type="number">
                        <List.Item><span style={documentStyle.textStyle}>Install App in your store.</span></List.Item>
                        <List.Item>
                            <span style={documentStyle.textStyle}>Select theme in which you want install app and click “Integrate” button.</span>
                            <div>
                                <img src={sc1} style={documentStyle.imgStyle} />
                            </div>
                        </List.Item>
                        <List.Item>
                            <span style={documentStyle.textStyle}>Once your theme is successfully install goto “Theme Customiser” modify the installed template according to your theme.</span>
                            <div>
                                <img src={sc2} style={documentStyle.imgStyle} />
                            </div>
                            </List.Item>
                        <List.Item>
                            <span style={documentStyle.textStyle}>Once you done with template modification goto “Import & Export”.</span>
                            
                            </List.Item>
                        <List.Item>
                            <span style={documentStyle.textStyle}>Here you have to import zipcode, if you have purchase our premium plan then you have access to “Bulk Import” otherwise “Single Import”.</span>
                            
                            </List.Item>
                        <List.Item>
                            <span style={documentStyle.textStyle}>So just add zipcode and select products for zipcode and click on save button.</span>
                            <p><strong style={documentStyle.textStyle}>Upload Zipcode</strong></p>
                            <div>
                                <img src={sc3} style={documentStyle.imgStyle} />
                            </div>
                            <p><strong style={documentStyle.textStyle}>Add Products to Zipcode</strong></p>
                            <div>
                                <img src={sc4} style={documentStyle.imgStyle} />
                            </div>
                            </List.Item>
                    </List>

                    <TextContainer>
                        <Banner >
                            <p style={documentStyle.bannerTextStyle}>For testing you can install with different themes and to completely remove our snippet from theme just “Disintegrate” before removing app from your store.</p>
                        </Banner>
                    </TextContainer>
                </Card>


                <Card title="Main Features" sectioned>
                    <List type="number">
                        <List.Item>
                            <span style={documentStyle.textStyle}><u>Integrate Themes :</u></span>
                            <p style={documentStyle.textStyle}>Merchants can integrate multiple theme with Zipster App but at a one theme can be integrated. For integration merchants have two options Automatic Installation or Manual Installation . A theme which is already integrated with one way is not possible to integrate with another. Merchants can Disintegrate to remove all snippets of Zipster App.</p>
                            <List type="bullet">
                                <List.Item>
                                    <span style={documentStyle.textStyle}>Automatic Installation :</span>
                                    <p style={documentStyle.textStyle}>This feature allows merchants to integrate and disintegrate themes with app. Merchants can add Zipster functionality for customer which only provides products available for customer’s Zipcode / Postal code.</p>
                                </List.Item>
                            </List>
                        </List.Item>
                        <List.Item>
                            <span style={documentStyle.textStyle}><u>Theme Customiser :</u></span>
                            <p style={documentStyle.textStyle}>Merchants can customise installed storefront snippet according to their theme design. Theme customiser allows merchants to customise button and model’s colors and texts according to their requirements. To make better user experience we provided  customiser with theme selection means they can first test in unpublished theme and replicate same design settings in published theme by their own.</p>
                            
                        </List.Item>
                        <List.Item>
                            <span style={documentStyle.textStyle}><u>Import & Export :</u></span>
                            <p style={documentStyle.textStyle}>Merchants need to add zipcodes and select set of product to make product appear when customer enter a specific zipcode on storefront. They can always remove a specific zipcode or all zipcode from a specific product. This functionality also allows merchants to export all the uploaded zipcodes in csv format.</p>
                            <List type="bullet">
                                <List.Item>
                                    <span style={documentStyle.textStyle}>Import :</span>
                                    <p style={documentStyle.textStyle}>This functionality allows merchants to add zipcodes based on their plans if they are using PREMIUM plan then they have access to bulk import and unlimited zipcode upload otherwise for FREE plan they can only access single import functionality with limited zipcode uploads.</p>
                                </List.Item>
                                <List.Item>
                                    <span style={documentStyle.textStyle}>Update :</span>
                                    <p style={documentStyle.textStyle}>This functionality allows merchants to remove single or all zipcode from a specific product.</p>
                                </List.Item>
                                <List.Item>
                                    <span style={documentStyle.textStyle}>Export :</span>
                                    <p style={documentStyle.textStyle}>Merchants can export all uploaded zipcode in CSV format using this functionality. </p>
                                </List.Item>
                            </List>
                        </List.Item>
                    </List>

                    <TextContainer>
                        <Banner >
                            <p style={documentStyle.bannerTextStyle}>Bulk Import only accepts csv format with “Zipcode” as title in first line and Sample CSV provided refer once before you upload any file. </p>
                        </Banner>
                    </TextContainer>
                </Card>
                <Card title="Extra Features" sectioned>
                <List type="number">
                        <List.Item><p style={documentStyle.textStyle}>Integration with multiple theme which makes easy for testing.</p></List.Item>
                        <List.Item>
                            <span style={documentStyle.textStyle}>Manual Installation :</span>
                            <p style={documentStyle.textStyle}>Its only suggested for shopify theme developer. This feature allow them to modify HTML Snippet, CSS Snippet, JS Snippet according to their theme and also Integrate with that theme is required.</p>
                        </List.Item>
                        <List.Item>
                            <span style={documentStyle.textStyle}>Update Zipcode :</span>
                            <p style={documentStyle.textStyle}>Merchants can find this option in “Inport & Export” update tab. They can remove zipcode from the specific product in easy way.</p>
                        </List.Item>
                        <List.Item>
                            <span style={documentStyle.textStyle}>Export Zipcode :</span>
                            <p style={documentStyle.textStyle}>Merchants can find this option in “Import & Export” export tab. They can download all zipcode uploaded by theme from here.</p>
                        </List.Item>
                        <List.Item>
                            <span style={documentStyle.textStyle}>Contact us :</span>
                            <p style={documentStyle.textStyle}>Merchants can contact us through this features regarding any issue or help they need to setup this app.</p>
                        </List.Item>
                    </List>
                </Card>
                <Card title="Example video">
                    <video style={{width:"100%",padding:"15px 0px"}} controls>
                        <source src={evideo} type="video/mp4" />
                    </video>
                </Card>
            </Layout.Section>
        
      
    </Page>
    );
}

export default ContactUsPage;   