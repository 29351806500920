import React,{useState} from 'react';
import {Card, TextField, FormLayout,Form, Layout,  Page, SkeletonPage, } from '@shopify/polaris';
import { Select } from '@shopify/polaris';
import { RangeSlider } from '@shopify/polaris';
import { Button } from '@shopify/polaris';

import { ChromePicker} from 'react-color';
import { BuyButtonMajor,DetailedPopUpMajor, ViewMajor} from '@shopify/polaris-icons';
import {  SkeletonBodyText,SkeletonDisplayText,TextContainer } from '@shopify/polaris';
import { Tabs,Toast,Spinner } from '@shopify/polaris';
import { Icon } from '@shopify/polaris';
import { Tag } from '@shopify/polaris';
import { FooterHelp } from '@shopify/polaris';
import { Link } from '@shopify/polaris';
import { Banner, InlineError } from '@shopify/polaris';
import axios from 'axios';
import {BASE_URL} from "./data";
import {ZipbarConfigurationData} from './data';


export default function ZipConfigPage(props)
{
    /*
      color picker styles
    */
   const colorPickerStyles = {
      
    color: {
      width: '36px',
      height: '14px',
      borderRadius: '2px',
    },
    swatch: {
      padding: '5px',
      background: '#fff',
      borderRadius: '1px',
      boxShadow: '0 0 0 1px rgba(0,0,0,.1)',
      display: 'inline-block',
      cursor: 'pointer',
    },
    popoverPosition:{
        position:"relative",
    },
    popover: {
      position: 'absolute',
      zIndex: '9999',
      right:"0px",
    },
    cover: {
      position: 'fixed',
      top: '0px',
      right: '0px',
      bottom: '0px',
      left: '0px',
     
    },

    };

    /**
     *  Toast Message
     */
    
    const [toastData,setToastData]=useState("");
    //Success
    const [activeToast,setToast]=useState(false);
    const toggleToast=(()=>setToast(!activeToast));
    const toastSuccessMarkup=(activeToast?<Toast content={toastData} onDismiss={toggleToast}  />:null);
    //Error

    const [activeErrorToast,setErrorToast]=useState(false);
    const toggleErrorToast=(()=>setErrorToast(!activeErrorToast));
    const toastErrorMarkup=(activeErrorToast?<Toast content={toastData} error onDismiss={toggleErrorToast}  />:null);
    // loading for submit button
    const [curSaveSettings,setSaveSettings]=useState(false);
    const spinnerMarkup=(<div style={{display:"flex", justifyContent:"flex-end"}}><Button><Spinner accessibilityLabel="Install Loading" size="small" /></Button></div>);

    /**
     *  Page Title
     */

    const PageStyle={
      mainStyle:{
        
        display:"flex",
        justifyContent:"space-between",
        width:"100%"
      },
      webPreview:{
        display:"flex",
        position: "absolute",
        right: "0",
      },
      anchorTag:{
        display:"flex",
        justifyContent:"space-between",
        padding:"0px 30px",
        color:"black",
        fontSize:"15px",
        width:"150px",
        whiteSpace:"nowrap",
      }
    }
    const getData=(data_list,type_data)=>{
        
        for(var i=0;i<data_list.length;i++){
          console.log(data_list);
          if(curThemeSetting in data_list[i])
          {
              
              return data_list[i][curThemeSetting][type_data];
          }
        }
      };


    const [curThemeSetting, setThemeSetting]=useState(props.installedTheme[0].value);
    const [buttonData,setButtonData]=useState(getData(props.settingData,"buttonFilter"));

    const [curFilterButtonIcon,setFilterButtonIcon]=useState(buttonData.iconURL);
    const handleFilterButtonIcon=(value)=>setFilterButtonIcon(value);


     /* Filter button text*/
     const [curFilterButtonText,setFilterButtonText]=useState(buttonData.buttonText);
     const handleFilterButtonText=((value)=>setFilterButtonText(value));
 
       /* Filter button text color input */
    const [curFilterTextColor,setFilterTextColor]=useState({displayPicker:false,color:buttonData.buttonTextColor});
    const handleFilterTextColor=(
      (color) => setFilterTextColor({displayPicker:curFilterTextColor.displayPicker,color:color.hex})
    );


    const [curFilterTextBgColor,setFilterTextBgColor]=useState({displayPicker:false,color:buttonData.buttonBgColor});



    /*
      Position select
    */
    const zipbarPositionOption=[
        {label:'Right',value:'right'},
        {label:'Left',value:'left'},
    ];  

    const [zipPositionSelected,setzipPositionSelection]=useState(buttonData.buttonSide);
    const handlePositionSelect=((value)=> setzipPositionSelection(value));

      /*
      Range slider for button
    */
   const [currentPosition,setCurrentPosition]=useState(buttonData.buttonTopPosition);
   const handleCurrentPosition=((value)=>setCurrentPosition(value));


   const [modelData,setModelData]=useState(getData(props.settingData,"filterModel"));

    /* Title text input */
    const [curTitleText,setTitleText]=useState(modelData.modelTitleText);
    const handleTitleText =((value)=>setTitleText(value));

    /*
    title background color input
    */
    const [curbgcolor,setbgColor]=useState({displayPicker:false,color:modelData.modelTitleBgColor});
    const handlebgColor=(
    (color) => setbgColor({displayPicker:curbgcolor.displayPicker,color:color.hex})
    );


    /*
      Title text color input 
    */ 
   const [curTitleTextColor,setTitleTextColor]=useState({displayPicker:false,color:modelData.modelTitleColor});
   const handleTitleTextColor=(
    (color) => setTitleTextColor({displayPicker:curTitleTextColor.displayPicker,color:color.hex})
  );


    /**
     *  Model Description Image
     */
    const [curModelDescriptionImage,setModelDescriptionImage]=useState(modelData.modelDescriptionImageURL);
    const handleModelDescriptionImage=(value)=>setModelDescriptionImage(value);
    /*
      Model description input 
    */ 
    const [curModelDescription,setModelDescription]=useState(modelData.modelDescription);
    const handleModelDescription=((updateValue)=>setModelDescription(updateValue));

    /*
      Model description text color input
    */
   const [curModelTextColor,setModelTextColor]=useState({displayPicker:false,color:modelData.modelDescriptionTextColor});
   const handleModelTextColor=(
     (color) => setModelTextColor({displayPicker:curModelTextColor.displayPicker,color:color.hex})
   );

   /*
    Form textbox background color input
    */
   const [curFormTextBgColor,setFormTextBgColor]=useState({displayPicker:false,color:modelData.modelFormTextBgColor});
   const handleFormTextBgColor=(
     (color) => setFormTextBgColor({displayPicker:curFormTextBgColor.displayPicker,color:color.hex})
   );

      /*
      Model background color input
    */
    const [curModelBgColor,setModelBgColor]=useState({displayPicker:false,color:modelData.modelBgColor});
    const handleModelBgColor=(
        (color) => setModelBgColor({displayPicker:curModelBgColor.displayPicker,color:color.hex})
    );

    /*
    Form textbox Text color input
    */
   const [curFormTextColor,setFormTextColor]=useState({displayPicker:false,color:modelData.modelFormTextColor});
   const handleFormTextColor=(
     (color) => setFormTextColor({displayPicker:curFormTextColor.displayPicker,color:color.hex})
   );


    /*
      Form Button text input
    */
    const [curButtonText,setButtonText]= useState(modelData.modelFormButtonText);
    const handleButtonText=(value)=> setButtonText(value);


    /*
    Form button background color input 
    */ 
    const [curFormButtonBgColor,setFormButtonBgColor]=useState({displayPicker:false,color:modelData.modelFormButtonBgColor});
    const handleFormButtonBgColor=(
    (color) => setFormButtonBgColor({displayPicker:curFormButtonBgColor.displayPicker,color:color.hex})
    );

     /*
        Form button Text color input
    */
   const [curFormButtonColor,setFormButtonColor]=useState({displayPicker:false,color:modelData.modelFormButtonTextColor});
   const handleFormButtonColor=(
     (color) => setFormButtonColor({displayPicker:curFormButtonColor.displayPicker,color:color.hex})
   );


    const [zipbarConfigData,setZipbarConfigData]=useState([]);

    const [isAutomatic, setIsAutomatic] = useState(true)

    const handleThemeSetting=(value)=>{

        ZipbarConfigurationData().then(result=> setZipbarConfigData(result.data))
        
        if (zipbarConfigData){
            let install_type = zipbarConfigData.themeData;
            if (install_type){

                for (var i=0;i<install_type.length;i++){
                    
                    if (value == install_type[i].value)
                    {
                        
                        if(install_type[i].install_type == 'manually'){
                            setIsAutomatic(false)
                           
                        }
                        else{
                            setIsAutomatic(true)
                        }
                    }
                }

            }
            


            // setThemeSetting(value)
            let data_list = zipbarConfigData.settingData;
           
            if (data_list){
                setThemeSetting(value)    
                for(var i=0;i<data_list.length;i++){
                   
                    if(value in data_list[i])
                    {
                      
                        setButtonData(data_list[i][value]['buttonFilter'])
                        setFilterButtonIcon(data_list[i][value]['buttonFilter']['iconURL'])
                        setFilterButtonText(data_list[i][value]['buttonFilter']['buttonText'])
                        setFilterTextColor({displayPicker:false,color:data_list[i][value]['buttonFilter']['buttonTextColor']})
                        setFilterTextBgColor({displayPicker:false,color:data_list[i][value]['buttonFilter']['buttonBgColor']})
                        setzipPositionSelection(data_list[i][value]['buttonFilter']['buttonSide'])
                        setCurrentPosition(data_list[i][value]['buttonFilter']['buttonTopPosition'])
                    
                        setModelData(data_list[i][value]['filterModel'])
                        setTitleText(data_list[i][value]['filterModel']['modelTitleText'])
                        setbgColor({displayPicker:false,color:data_list[i][value]['filterModel']['modelTitleBgColor']})
                        setTitleTextColor({displayPicker:false,color:data_list[i][value]['filterModel']['modelTitleColor']})
                        setModelDescriptionImage(data_list[i][value]['filterModel']['modelDescriptionImageURL'])
                        setModelDescription(data_list[i][value]['filterModel']['modelDescription'])
                        setModelTextColor({displayPicker:false,color:data_list[i][value]['filterModel']['modelDescriptionTextColor']})
                        setFormTextBgColor({displayPicker:false,color:data_list[i][value]['filterModel']['modelFormTextBgColor']})
                        setModelBgColor({displayPicker:false,color:data_list[i][value]['filterModel']['modelBgColor']})
                        setFormTextColor({displayPicker:false,color:data_list[i][value]['filterModel']['modelFormTextColor']})
                        setButtonText(data_list[i][value]['filterModel']['modelFormButtonText'])
                        setFormButtonBgColor({displayPicker:false,color:data_list[i][value]['filterModel']['modelFormButtonBgColor']})
                        setFormButtonColor({displayPicker:false,color:data_list[i][value]['filterModel']['modelFormButtonTextColor']})
    
                    }
                  }
            }
        }

    };
    const PageTitle=(
    <div style={PageStyle.mainStyle}>
      <span>Theme Customiser</span>
      <div style={PageStyle.webPreview}>
        <a href={"https://"+props.domainName+"?preview_theme_id="+curThemeSetting} target={"_blank"} style={PageStyle.anchorTag} >
          <Icon source={ViewMajor}/>
          Preview
        </a>
        <div style={{width:"350px"}}>
          <Card>
            <Select
              label="Themes : "
              labelInline
              options={props.installedTheme}
              onChange={handleThemeSetting}
              value={curThemeSetting}
            />
          </Card>
        </div>
      </div>
    </div>
    );
    console.log("Current Theme ",curThemeSetting);
   
   const handleFilterTextClose =(()=>setFilterTextColor({displayPicker:false,color:curFilterTextColor.color}));
   const handleFilterTextClick=(()=>setFilterTextColor({displayPicker:!curFilterTextColor.displayPicker,color:curFilterTextColor.color}));
   const FilterTextColorPicker=(<div style={colorPickerStyles.popoverPosition}><div style={colorPickerStyles.popover} >
     <div style={colorPickerStyles.cover} onClick={handleFilterTextClose}/>
     <ChromePicker
       color={curFilterTextColor.color}
       onChange={handleFilterTextColor}
     />
   </div></div>);
   const toggleFilterTextColorPicker=curFilterTextColor.displayPicker?FilterTextColorPicker:null;

   /* Filter button text background color input */

   
   const handleFilterTextBgColor=(
     (color) => setFilterTextBgColor({displayPicker:curFilterTextBgColor.displayPicker,color:color.hex})
   );
   const handleFilterTextBgClose =(()=>setFilterTextBgColor({displayPicker:false,color:curFilterTextBgColor.color}));
   const handleFilterTextBgClick=(()=>setFilterTextBgColor({displayPicker:!curFilterTextBgColor.displayPicker,color:curFilterTextBgColor.color}));
   const FilterTextBgColorPicker=(<div style={colorPickerStyles.popoverPosition}><div style={colorPickerStyles.popover} >
     <div style={colorPickerStyles.cover} onClick={handleFilterTextBgClose}/>
     <ChromePicker
       color={curFilterTextBgColor.color}
       onChange={handleFilterTextBgColor}
     />
   </div></div>);
   const toggleFilterTextBgColorPicker=curFilterTextBgColor.displayPicker?FilterTextBgColorPicker:null;


    /*
      Shopify label size 
    */ 
    const shopifyLabel={fontSize:'14px',lineHeight:'20px',fontWeight:'400'}
    
    
    
   

    const handlebgclose =(()=>setbgColor({displayPicker:false,color:curbgcolor.color}));
    const handlebgclick=(()=>setbgColor({displayPicker:!curbgcolor.displayPicker,color:curbgcolor.color}));

    const colorPicker=(<div style={colorPickerStyles.popoverPosition}><div style={colorPickerStyles.popover} >
      <div style={colorPickerStyles.cover} onClick={handlebgclose}/>
      <ChromePicker
        
        color={curbgcolor.color}
        onChange={handlebgColor}
      />
    </div></div>);

    const toggleColorPicker=curbgcolor.displayPicker?colorPicker:null;



  const handleTitleTextClose =(()=>setTitleTextColor({displayPicker:false,color:curTitleTextColor.color}));
  const handleTitleTextClick=(()=>setTitleTextColor({displayPicker:!curTitleTextColor.displayPicker,color:curTitleTextColor.color}));
  const TitleTextColorPicker=(<div style={colorPickerStyles.popoverPosition}><div style={colorPickerStyles.popover} >
    <div style={colorPickerStyles.cover} onClick={handleTitleTextClose}/>
    <ChromePicker
      color={curTitleTextColor.color}
      onChange={handleTitleTextColor}
    />
  </div></div>);
  const toggleTitleTextColorPicker=curTitleTextColor.displayPicker?TitleTextColorPicker:null;

  
   const handleModelTextClose =(()=>setModelTextColor({displayPicker:false,color:curModelTextColor.color}));
   const handleModelTextClick=(()=>setModelTextColor({displayPicker:!curModelTextColor.displayPicker,color:curModelTextColor.color}));
   
   const ModelTextColorPicker=(<div style={colorPickerStyles.popoverPosition}><div style={colorPickerStyles.popover} >
     <div style={colorPickerStyles.cover} onClick={handleModelTextClose}/>
     <ChromePicker
       color={curModelTextColor.color}
       onChange={handleModelTextColor}
     />
   </div></div>);
   const toggleModelTextColorPicker=curModelTextColor.displayPicker?ModelTextColorPicker:null;




   const handleModelBgClose =(()=>setModelBgColor({displayPicker:false,color:curModelBgColor.color}));
   const handleModelBgClick=(()=>setModelBgColor({displayPicker:!curModelBgColor.displayPicker,color:curModelBgColor.color}));
   const ModelBgColorPicker=(<div style={colorPickerStyles.popoverPosition}><div style={colorPickerStyles.popover} >
     <div style={colorPickerStyles.cover} onClick={handleModelBgClose}/>
     <ChromePicker
       color={curModelBgColor.color}
       onChange={handleModelBgColor}
     />
   </div></div>);
   const toggleModelBgColorPicker=curModelBgColor.displayPicker?ModelBgColorPicker:null;


    
    const handleFormTextBgClose =(()=>setFormTextBgColor({displayPicker:false,color:curFormTextBgColor.color}));
    const handleFormTextBgClick=(()=>setFormTextBgColor({displayPicker:!curFormTextBgColor.displayPicker,color:curFormTextBgColor.color}));
    const FormTextBgColorPicker=(<div style={colorPickerStyles.popoverPosition}><div style={colorPickerStyles.popover} >
      <div style={colorPickerStyles.cover} onClick={handleFormTextBgClose}/>
      <ChromePicker
        color={curFormTextBgColor.color}
        onChange={handleFormTextBgColor}
      />
    </div></div>);
    const toggleFormTextBgColorPicker=curFormTextBgColor.displayPicker?FormTextBgColorPicker:null;

    
    const handleFormTextClose =(()=>setFormTextColor({displayPicker:false,color:curFormTextColor.color}));
    const handleFormTextClick=(()=>setFormTextColor({displayPicker:!curFormTextColor.displayPicker,color:curFormTextColor.color}));
    const FormTextColorPicker=(<div style={colorPickerStyles.popoverPosition}><div style={colorPickerStyles.popover} >
      <div style={colorPickerStyles.cover} onClick={handleFormTextClose}/>
      <ChromePicker
        color={curFormTextColor.color}
        onChange={handleFormTextColor}
      />
    </div></div>);
    const toggleFormTextColorPicker=curFormTextColor.displayPicker?FormTextColorPicker:null;




    const handleFormButtonBgClose =(()=>setFormButtonBgColor({displayPicker:false,color:curFormButtonBgColor.color}));
    const handleFormButtonBgClick=(()=>setFormButtonBgColor({displayPicker:!curFormButtonBgColor.displayPicker,color:curFormButtonBgColor.color}));
    const FormButtonBgColorPicker=(<div style={colorPickerStyles.popoverPosition}><div style={colorPickerStyles.popover} >
      <div style={colorPickerStyles.cover} onClick={handleFormButtonBgClose}/>
      <ChromePicker
        color={curFormButtonBgColor.color}
        onChange={handleFormButtonBgColor}
      />
    </div></div>);
    const toggleFormButtonBgColorPicker=curFormButtonBgColor.displayPicker?FormButtonBgColorPicker:null;

   
    const handleFormButtonClose =(()=>setFormButtonColor({displayPicker:false,color:curFormButtonColor.color}));
    const handleFormButtonClick=(()=>setFormButtonColor({displayPicker:!curFormButtonColor.displayPicker,color:curFormButtonColor.color}));
    const FormButtonColorPicker=(<div style={colorPickerStyles.popoverPosition}><div style={colorPickerStyles.popover} >
      <div style={colorPickerStyles.cover} onClick={handleFormButtonClose}/>
      <ChromePicker
        color={curFormButtonColor.color}
        onChange={handleFormButtonColor}
      />
    </div></div>);
    const toggleFormButtonColorPicker=curFormButtonColor.displayPicker?FormButtonColorPicker:null;

      /*---------------------------------------------------
          Preview section
          
      */
      
      const previewStyles={
        boxSizing:"border-box",
        borderTop:"1px solid #5B5B5B",
        borderBottom:"1px solid #5B5B5B",
        borderRight:"1px solid #5B5B5B",
        borderLeft:"1px solid #5B5B5B",
        position:"relative",
        overflow:"hidden",
        maxHeight:"500px",
      }
      const btnStyles={
      
      mainStyles:{
        position:"absolute",
        zIndex:"99",
        top:currentPosition+"%",
        right:(zipPositionSelected.toLowerCase()==="left"?"auto":"0px"),
        backgroundColor:curFilterTextBgColor.color,
        padding:"5px 10px",
        boxShadow:"0px -1px 5px 0px rgba(50, 50, 50, 0.3)",
        cursor:"pointer",
        fontSize:"20px",
        color:curFilterTextColor.color,
        display:"flex",
      },
      spanStyle:{
        paddingRight:(curFilterButtonText.length>0 && curFilterButtonIcon.length>0?"5px":"0px"),
      },
      imgStyle:{
        width:"20px",
        border:"1px solid transparent",
        borderRadius:"50%",
      },

    }

      const modelPreviewStyles={
        popup:{
          position:"absolute",
          zIndex:"99",
          top:"0px",
          left:"0px",
          height:"100%",
          width:"100%",
          
        },
        back:{
          position:"absolute",
          zIndex:"-5px",
          height:"100%",
          width:"100%",
          backgroundColor:"black",
          opacity:"0.5",
        },
        front:{
          position:"absolute",
          zIndex:"1px",
          top:"7%",
          left:"30%",
          backgroundColor:curModelBgColor.color,
        },
        title:{
          width:"100%",
          padding:"15px 30px",
          backgroundColor:curbgcolor.color,
          color:curTitleTextColor.color,
        },
        description:{
          maxWidth:"350px",
          padding:"30px 30px 15px 30px",
          color:curModelTextColor.color,
          fontSize:"15px",
        },
        form:{
          width:"100%",
          padding:"0px 30px 30px 30px",
        },
        input:{
          marginRight:"10px",
          border:"0px",
          backgroundColor:curFormTextBgColor.color,
          padding:"12px",
          fontSize:"15px",
          color:curFormTextColor.color,
        },
        
        button:{
          display:"inline-block",
          fontWeight:"400",
          lineHeight:"1.5",
          textAlign:"center",
          textDecoration:"none",
          verticalALign:"middle",
          cursor:"pointer",
          border:"1px solid transparent",
          padding:"10px 18px",
          borderRadius:".25rem",
          transition:"all .15s ease-in-out",
          color:curFormButtonColor.color,
          backgroundColor:curFormButtonBgColor.color,
          borderColor:curFormButtonBgColor.color,
          fontSize:"15px",
        },
        
        titleH4:{
          margin:"0px",
          textTransform:"uppercase",
          fontSize:"20px",
        },
        imgStyle:{
          width:"300px",
          height:"160px",
        },
        
      }

      /* Preview toggle */
      // const [curPreview,setPreview]=useState(false);
      // const handlePreview=(()=> setPreview(!curPreview));

      /* Filter Button preview */

      const filterButtonPreview=(
        <div style={btnStyles.mainStyles}><span style={btnStyles.spanStyle}>{curFilterButtonIcon.length>0?<img src={curFilterButtonIcon}  style={btnStyles.imgStyle} />:null}</span><span>{curFilterButtonText}</span></div>
      );

        const filterModelPreview=(
            <div style={modelPreviewStyles.popup}>
                <div style={modelPreviewStyles.back}></div>
                <div style={modelPreviewStyles.front}>
                    <div style={modelPreviewStyles.title}>
                        <h4 style={modelPreviewStyles.titleH4}>{curTitleText}</h4>
                    </div>
                    <div style={modelPreviewStyles.description}>
                        {curModelDescriptionImage!=""?<img src={curModelDescriptionImage} style={modelPreviewStyles.imgStyle} />:null}
                        <div style={{lineHeight:"2"}}>{curModelDescription}</div>
                    </div>
                    <form style={modelPreviewStyles.form} >
                        <input placeholder="Zipcode/Postal Code" style={modelPreviewStyles.input} />
                        {curButtonText!=""?<button style={modelPreviewStyles.button} disabled >{curButtonText}</button>:null}
                    </form>
                </div>
            </div>
        );
      
      /* Preview tabs */
      const [curPreviewTab,setPreviewTab]=useState(0);
      const handlePreviewTab=((index)=>setPreviewTab(index));
      const previewTabs=[ 
      {
        id:"buttonFilter",
        content:(<Icon source={BuyButtonMajor}></Icon>),
        panelID:"buttonFilterPanel"
      },
      {
        id:"popupFilter",
        content:(<Icon source={DetailedPopUpMajor}></Icon>),
        panelID:"poppupFilterPanel"
      }
    ];

    

    const resetButtonData=()=>{
        setFilterButtonIcon(buttonData.iconURL);
        setFilterButtonText(buttonData.buttonText);
        setFilterTextBgColor({displayPicker:false,color:buttonData.buttonBgColor})
        setFilterTextColor({displayPicker:false,color:buttonData.buttonTextColor})
        setzipPositionSelection(buttonData.buttonSide)
        setCurrentPosition(buttonData.buttonTopPosition)
    }

    /**
     *  Check if any changes in button settings then only user can submit 
     */

    const filterSubmitButton=(
      <>
        {buttonData.iconURL==curFilterButtonIcon 
        && buttonData.buttonText==curFilterButtonText 
        && buttonData.buttonTextColor==curFilterTextColor.color
        && buttonData.buttonBgColor==curFilterTextBgColor.color
        && buttonData.buttonSide==zipPositionSelected
        && buttonData.buttonTopPosition==currentPosition
        ?<div style={{display:"flex",justifyContent:"flex-end"}}><Button>Save</Button></div>
        :<div style={{display:'flex',justifyContent:'space-between'}}>
          <div style={{color:"red"}}>
            <Button onClick={resetButtonData} monochrome outline>
              Discard
            </Button>
          </div>
          <div style={{color:"green"}}>
            <Button submit monochrome outline>
              Save
            </Button>
          </div>
      </div>}
      </>
    );
      
    /**
     *  validation error message
     */
      
     const [curError,setError]=useState({isError:false,error:""});
     const handleErrorDismiss=()=>setError({isError:false,error:""})
    
     /**
      *  change properties data 
      */
          const handleChangeProps=(type_data)=>{
            if(type_data==="button")
            {
              var btnFlt={"iconURL":curFilterButtonIcon
              ,"buttonText":curFilterButtonText,"buttonTextColor":curFilterTextColor.color
              ,"buttonBgColor":curFilterTextBgColor.color,"buttonSide":zipPositionSelected
              ,"buttonTopPosition":currentPosition};
              setButtonData(btnFlt);
            }
            else{
              var FltMdl={"modelTitleText":curTitleText
              ,"modelTitleBgColor":curbgcolor.color
              ,"modelTitleColor":curTitleTextColor.color
              ,"modelDescriptionImageURL":curModelDescriptionImage
              ,"modelDescription":curModelDescription
              ,"modelDescriptionTextColor":curModelTextColor.color
              ,"modelBgColor":curModelBgColor.color
              ,"modelFormTextBgColor":curFormTextBgColor.color
              ,"modelFormTextColor":curFormTextColor.color 
              ,"modelFormButtonText":curButtonText
              ,"modelFormButtonBgColor":curFormButtonBgColor.color
              ,"modelFormButtonTextColor":curFormButtonColor.color};
              setModelData(FltMdl)
            }
          }

    /**
     *  Submit method for filter button
     */
    
    const handleButtonSubmit=(_event)=>{
 
      if (isAutomatic == true){
          
          if(curFilterButtonIcon!=="" || curFilterButtonText!=="")
          {  
              
               setSaveSettings(true)
              const store_id=localStorage.getItem("store_id")
              const sendButtonData={"store_id":store_id
              ,"theme_id":curThemeSetting
                ,"buttonFilter":{"iconURL":curFilterButtonIcon==""?"#":curFilterButtonIcon
                ,"buttonText":curFilterButtonText,"buttonTextColor":curFilterTextColor.color
                ,"buttonBgColor":curFilterTextBgColor.color,"buttonSide":zipPositionSelected
                ,"buttonTopPosition":currentPosition}};
              axios({
                method:"post",
                url:BASE_URL+"api/get-zipbarConfig",
                data:sendButtonData
              }).then(response=>{
                const data=response.data;
                if(data[0]==="200")
                {
                    setToastData("Theme Integrated Successfully.");
                    toggleToast();
                    handleChangeProps("button")
                    setSaveSettings(false);
                }
                else if (data[0]==="500")
                {
                    setToastData("Internal Server Error,Reload might solve problem.Contact Support for help");
                    toggleErrorToast();
                    setSaveSettings(false);
                }
                else
                {
                    setToastData("Resource not found, Retry from shopify.");
                    toggleErrorToast();
                    setSaveSettings(false);
    
                }
              }).catch((error)=>{
                setToastData("Internal Server Error. Contact Support for help");
                toggleErrorToast();
                setSaveSettings(false);
              }
              );
          }
          else{

                
              setError({isError:true,error:"Filter button Text or Icon at least one field is required. "})
          }
      }else{
            setSaveSettings(true);
            setToastData("Mainually theme configureation not allow!!");
            toggleErrorToast();
            setSaveSettings(false);
            setError({isError:true,error:"Mainually theme configureation not allow!!"})

      }

    }

    // button settings

    const buttonSettings=(
      <Layout.Section>
        <Card title="Zipbar Filter Button" sectioned>
            <Form onSubmit={handleButtonSubmit}>
            <FormLayout>
                {/* Filter button Icon */}
                <TextField
                label={<div>Filter Button Icon <Tag>Link</Tag></div>}
                value={curFilterButtonIcon}
                onChange={handleFilterButtonIcon}
                />

                {/* Filter text input */}
                <TextField
                label={<div>Filter Button Text <Tag>20 chars</Tag></div>}
                value={curFilterButtonText}
                onChange={handleFilterButtonText}
                maxLength={20}
                showCharacterCount
                />

                {/* Filter button text color input */}

                <div style={{display:'flex',justifyContent:'space-between'}}>
                <div style={shopifyLabel}>Filter Button Text Color</div>
                <div >
                    <div style={colorPickerStyles.swatch} onClick={handleFilterTextClick}>
                    <div style={{backgroundColor:curFilterTextColor.color,width:'36px',height:'14px',borderRadius:'2px'}} />
                    </div>
                    {toggleFilterTextColorPicker}
                </div>
                </div>

                {/* Filter button text background color input */}

                <div style={{display:'flex',justifyContent:'space-between'}}>
                <div style={shopifyLabel}>Filter Button Text Background Color</div>
                <div >
                    <div style={colorPickerStyles.swatch} onClick={handleFilterTextBgClick}>
                    <div style={{backgroundColor:curFilterTextBgColor.color,width:'36px',height:'14px',borderRadius:'2px'}} />
                    </div>
                    {toggleFilterTextBgColorPicker}
                </div>
                </div>

                {/* Filter button Type*/}
                <Select
                label='Button Side'
                options={zipbarPositionOption}
                onChange={handlePositionSelect}
                value={zipPositionSelected}
                />

                {/* Filter position from top */}
                <RangeSlider
                output
                label="Top position"
                min='0'
                max='100'
                value={currentPosition}
                onChange={handleCurrentPosition}
                prefix={<p style={{fontStyle:"bold"}}>0%</p>}
                suffix={<p>100%</p>}
                />
                {curSaveSettings?spinnerMarkup:filterSubmitButton}
                {curError.isError && curFilterButtonIcon==="" && curFilterButtonText===""?<Banner status="critical" onDismiss={handleErrorDismiss} >
                <div style={{color:"red", fontSize:"16px" }}>
                    <TextContainer >
                    {curError.error}
                    </TextContainer>
                </div>
                </Banner>:null}
            </FormLayout>
            </Form>
        </Card>
        </Layout.Section>
    );

    const resetModelData=()=>{
        setTitleText(modelData.modelTitleText);
        setbgColor({displayPicker:false,color:modelData.modelTitleBgColor})
        setTitleTextColor({displayPicker:false,color:modelData.modelTitleColor})
        setModelDescriptionImage(modelData.modelDescriptionImageURL)
        setModelDescription(modelData.modelDescription)
        setModelTextColor({displayPicker:false,color:modelData.modelDescriptionTextColor})
        setModelBgColor({displayPicker:false,color:modelData.modelBgColor})
        setFormTextBgColor({displayPicker:false,color:modelData.modelFormTextBgColor})
        setFormTextColor({displayPicker:false,color:modelData.modelFormTextColor})
        setButtonText(modelData.modelFormButtonText)
        setFormButtonBgColor({displayPicker:false,color:modelData.modelFormButtonBgColor})
        setFormButtonColor({displayPicker:false,color:modelData.modelFormButtonTextColor})
    }

      /**
       *  Check if any changes in model settings then only user can submit
       */
      
      const filterSubmitModel=(
        <>
          {modelData.modelTitleText==curTitleText 
          && modelData.modelTitleBgColor==curbgcolor.color 
          && modelData.modelTitleColor==curTitleTextColor.color
          && modelData.modelDescriptionImageURL==curModelDescriptionImage
          && modelData.modelDescription==curModelDescription
          && modelData.modelDescriptionTextColor==curModelTextColor.color
          && modelData.modelBgColor==curModelBgColor.color
          && modelData.modelFormTextBgColor==curFormTextBgColor.color
          && modelData.modelFormTextColor==curFormTextColor.color
          && modelData.modelFormButtonText==curButtonText
          && modelData.modelFormButtonBgColor==curFormButtonBgColor.color
          && modelData.modelFormButtonTextColor==curFormButtonColor.color
          ?<div style={{display:"flex",justifyContent:"flex-end"}}><Button>Save</Button></div>
          :<div style={{display:'flex',justifyContent:'space-between'}}>
            <div style={{color:"red"}}>
              <Button onClick={resetModelData} monochrome outline>
                Discard
              </Button>
            </div>
            <div style={{color:"green"}}>
              <Button submit monochrome outline disabled={!isAutomatic}>
                Save
              </Button>
            </div>
          </div>}
        </>
      );
      
      
      const handleModelSubmit=(_event)=>{


        if (isAutomatic == true)
        {

            setSaveSettings(true);
            const store_id=localStorage.getItem("store_id");
            const sendModelData={"store_id":store_id
            ,"theme_id":curThemeSetting
            ,"filterModel":{"modelTitleText":curTitleText
            ,"modelTitleBgColor":curbgcolor.color
            ,"modelTitleColor":curTitleTextColor.color
            ,"modelDescriptionImageURL":curModelDescriptionImage==""?"#":curModelDescriptionImage
            ,"modelDescription":curModelDescription
            ,"modelDescriptionTextColor":curModelTextColor.color
            ,"modelBgColor":curModelBgColor.color
            ,"modelFormTextBgColor":curFormTextBgColor.color
            ,"modelFormTextColor":curFormTextColor.color 
            ,"modelFormButtonText":curButtonText
            ,"modelFormButtonBgColor":curFormButtonBgColor.color
            ,"modelFormButtonTextColor":curFormButtonColor.color}}
            axios({
              method:"post",
              url:BASE_URL+"api/get-zipbarConfig",
              data:sendModelData
            }).then((response)=>{
              const data=response.data;
              if(data[0]==="200")
              {
                  setToastData("Zipbar configurations successfully.");
                  toggleToast();
                  handleChangeProps("model")
                  setSaveSettings(false);
              }
              else if (data[0]==="500")
              {
                  setToastData("Internal Server Error.Contact Support for help");
                  toggleErrorToast();
                  setSaveSettings(false);
              }
              else
              {
                  setToastData("Resource not found, Retry from shopify.");
                  toggleErrorToast();
                  setSaveSettings(false);
  
              }
            }).catch((error)=>{
              setToastData("Internal Server Error,Reload might solve problem. Contact Support for help");
              toggleErrorToast();
              setSaveSettings(false);
            });
        }
        else{

            setSaveSettings(true);
            setToastData("Mainually theme configureation not allow!!");
            toggleErrorToast();
            setSaveSettings(false);
            setError({isError:true,error:"Mainually theme configureation not allow!!"})
        }
      }

      
      // model settings 
      /* Zipbar Filter Model sections */
      const modelSettings=(<Layout.Section
                    
        >
          <Card title="Zipbar Filter Model" sectioned>
              <Form onSubmit={handleModelSubmit}>
                <FormLayout>
                
                  {/* Title text input*/}
                  <TextField 
                    label={<div>Model Title Text <Tag>20 chars</Tag></div>}
                    value={curTitleText}
                    onChange={handleTitleText}
                    maxLength={20}
                  />

                  {/* Title bg color input*/ }
                  <div style={{display:'flex',justifyContent:'space-between'}}>
                    <div style={shopifyLabel}>Title Background Color</div> 
                    <div >
                      <div style={colorPickerStyles.swatch} onClick={handlebgclick}>
                        <div style={{backgroundColor:curbgcolor.color,width:'36px',height:'14px',borderRadius:'2px'}} />
                      </div>
                      {toggleColorPicker}
                    </div>
                  </div>

                  {/* Title text color input*/}
                  <div style={{display:'flex',justifyContent:'space-between'}}>
                    <div style={shopifyLabel}>Title Text Color</div>
                    <div >
                      <div style={colorPickerStyles.swatch} onClick={handleTitleTextClick}>
                        <div style={{backgroundColor:curTitleTextColor.color,width:'36px',height:'14px',borderRadius:'2px'}} />
                      </div>
                      {toggleTitleTextColorPicker}
                    </div>
                  </div>


                  {/* Model Description image  */}

                  <TextField
                      label={<div>Model Description Image <Tag>Link</Tag></div>}
                      value={curModelDescriptionImage}
                      onChange={handleModelDescriptionImage}

                  />

                  {/* Model description*/}
                  <TextField 
                    label={<div>Model Description <Tag>100 chars</Tag></div>}
                    value={curModelDescription}
                    onChange={handleModelDescription}
                    multiline={2}
                    maxLength={100}
                    showCharacterCount
                  />

                  {/* Model description text color */}
              
                  <div style={{display:'flex',justifyContent:'space-between'}}>
                    <div style={shopifyLabel}>Model Description Text Color</div>
                    <div >
                      <div style={colorPickerStyles.swatch} onClick={handleModelTextClick}>
                        <div style={{backgroundColor:curModelTextColor.color,width:'36px',height:'14px',borderRadius:'2px'}} />
                      </div>
                      {toggleModelTextColorPicker}
                    </div>
                  </div>

                  {/* Model backgraound color */}
                    <div style={{display:'flex',justifyContent:'space-between'}}>
                        <div style={shopifyLabel}>Model Background Color</div>
                        <div >
                        <div style={colorPickerStyles.swatch} onClick={handleModelBgClick}>
                            <div style={{backgroundColor:curModelBgColor.color,width:'36px',height:'14px',borderRadius:'2px'}} />
                        </div>
                            {toggleModelBgColorPicker}
                        </div>
                    </div>


                  {/* Form textbox background color */}
                  <div style={{display:'flex',justifyContent:'space-between'}}>
                    <div style={shopifyLabel}>Form Textbox Background Color</div>
                    <div >
                      <div style={colorPickerStyles.swatch} onClick={handleFormTextBgClick}>
                            <div style={{backgroundColor:curFormTextBgColor.color,width:'36px',height:'14px',borderRadius:'2px'}} />
                      </div>
                            {toggleFormTextBgColorPicker}
                    </div>
                  </div>
                  
                  {/* Form textbox text color */}
                  <div style={{display:'flex',justifyContent:'space-between'}}>
                    <div style={shopifyLabel}>Form Textbox Text Color</div>
                    <div >
                      <div style={colorPickerStyles.swatch} onClick={handleFormTextClick}>
                        <div style={{backgroundColor:curFormTextColor.color,width:'36px',height:'14px',borderRadius:'2px'}} />
                      </div>
                      {toggleFormTextColorPicker}
                    </div>
                  </div>

                  {/* Form Button Text input*/}
                  <TextField
                    label={<div>Form Button Text <Tag>10 chars</Tag></div>}
                    value={curButtonText}
                    onChange={handleButtonText}
                    maxLength={10}
                    showCharacterCount
                  />

                  {/* Form button background color*/}
                  <div style={{display:'flex',justifyContent:'space-between'}}>
                    <div style={shopifyLabel}>Form Button Background Color</div>
                    <div >
                      <div style={colorPickerStyles.swatch} onClick={handleFormButtonBgClick}>
                        <div style={{backgroundColor:curFormButtonBgColor.color,width:'36px',height:'14px',borderRadius:'2px'}} />
                      </div>
                      {toggleFormButtonBgColorPicker}
                    </div>
                  </div>

                  {/* Form button text color */}
                  <div style={{display:'flex',justifyContent:'space-between'}}>
                    <div style={shopifyLabel}>Form Button Text Color</div>
                    <div >
                      <div style={colorPickerStyles.swatch} onClick={handleFormButtonClick}>
                        <div style={{backgroundColor:curFormButtonColor.color,width:'36px',height:'14px',borderRadius:'2px'}} />
                      </div>
                      {toggleFormButtonColorPicker}
                    </div>
                  </div>

                  {/* Save button for model*/}
                  {curSaveSettings?spinnerMarkup:filterSubmitModel}
                
                </FormLayout>
              </Form>
            </Card>
          </Layout.Section>);


        const markupSettings=(curPreviewTab?modelSettings:buttonSettings);
//description="We know you want a simpliest way to access. Our automatic preview will help you. Just select on element you want to edit and preview will automatic toggle."
      
    
        
      

        // console.log(zipbarConfigData==null);

        /* Return the Zipbar Configuration Page */
        return (
          
            <Page  title={PageTitle} fullWidth separator>
                 {isAutomatic===false?<InlineError message="Only for Automatic integration theme" fieldID="curSubject" />:null }
                    
              <Layout>
                {/*  Preview sections */}
                    <div style={{width:"65%",paddingRight:"20px"}}>
                        
                    <Layout.Section oneHalf>
                    <Card sectioned  title="Preview of Zipcode Filter" >
                    
                      <div>
                          

                       {/* <Card.Section>
                          <Button fullWidth ariaControls="basic-collapsible" onClick={handlePreview} ariaExpanded={curPreview} >
                            Take a quick Preview
                          </Button>
                       </Card.Section>*/}
                        <Card>
                          {/* <Collapsible open={curPreview} id="previewBox" transition={{duration:'500ms',timingFunction:'ease-in-out'}} expandOnPrint> */}
                            {/* Preview Tabs */}
                            <div style={{display:"flex",justifyContent:"center"}}>
                              <Card.Section>
                                <Tabs tabs={previewTabs} selected={curPreviewTab} onSelect={handlePreviewTab}  fitted >
                                </Tabs>
                              </Card.Section>
                            </div>
                            {/* Preview tab display */}
                            <div style={previewStyles}>
                                <SkeletonPage  primaryAction secondaryActions={5}>
                                    <Layout>
                                      <Layout.Section>
                                        <Card sectioned>
                                          <SkeletonBodyText />
                                        </Card>
                                        <Card sectioned>
                                          <TextContainer>
                                            <SkeletonDisplayText size="small" />
                                            <SkeletonBodyText />
                                          </TextContainer>
                                        </Card>
                                        <Card sectioned>
                                          <TextContainer>
                                            <SkeletonDisplayText size="small" />
                                            <SkeletonBodyText />
                                          </TextContainer>
                                        </Card>
                                      </Layout.Section>
                                      <Layout.Section secondary>
                                        <Card>
                                          <Card.Section>
                                            <TextContainer>
                                              <SkeletonDisplayText size="small" />
                                              <SkeletonBodyText lines={2} />
                                            </TextContainer>
                                          </Card.Section>
                                          <Card.Section>
                                            <SkeletonBodyText lines={1} />
                                          </Card.Section>
                                        </Card>
                                        <Card subdued>
                                          <Card.Section>
                                            <TextContainer>
                                              <SkeletonDisplayText size="small" />
                                              <SkeletonBodyText lines={2} />
                                            </TextContainer>
                                          </Card.Section>
                                          <Card.Section>                                      
                                            <SkeletonBodyText lines={2} />
                                          </Card.Section>
                                        </Card>
                                      </Layout.Section>
                                    </Layout>
                                  </SkeletonPage>
                                  {curPreviewTab===0?filterButtonPreview:filterModelPreview }
                              </div>
                          {/* </Collapsible> */}
                        </Card>
                      </div>
                    </Card>
                  </Layout.Section>
                  </div>
              {/*  Zipbar Filter Button*/}
              <div style={{paddingTop:"20px",width:"35%"}}>
                    <Layout oneHalf>
                    {markupSettings}
                    </Layout>
                </div>
        </Layout>
        
        {toastSuccessMarkup}
        {toastErrorMarkup}
      </Page>
    )
}

