import React,{useState,useEffect} from 'react';
import notFound from "../assets/images/404.png";
import ErrorPage from './ErrorPage';
import {LoadContactUs} from './LoadingPage';
import ContactUsPage from './ContactUsPage'
import { getStoreDetails } from './data';

function ContectAsMiddleHook() {

     /**
     *     Sender Name 
     */
    const [curName,setName]=useState();
    

    /**
     *     Sender Email 
     */
    const [curEmail,setEmail]=useState();
    
    const [loading, setloading] = useState(true)
  
    useEffect(()=>{
        
            getStoreDetails().then(result=> {
            
                setName(result.data[0]['store_name'])
                setEmail(result.data[0]['store_email'])
            
            })
            
            setloading(false)
      

        return () => {
            setName(); 
            setEmail();
            
          };
    
    
    },[]);
   
    
    const ThemeMessage=(<>Look like you haven't integrated our store .<br/>
    You can customise your filter look here after you integrate.</>);

    return (
        <div style={{height:"100%"}}>
           
            {loading != true && curName!=null && curEmail !=null?
            <div style={{height:"100%"}}>
                {curName.length>0
                
                ?<ContactUsPage curName={curName} curEmail={curEmail} />
                :<ErrorPage message={ThemeMessage} errorImage={notFound} imageWidth="600" />}
            </div>
            :<LoadContactUs/>}
        </div>
    );
}

export default ContectAsMiddleHook;