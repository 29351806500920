import axios from "axios";

export const BASE_URL="https://zipcode.webiots.com/";


export const ThemesOptionData = async () =>{
    const store_id=localStorage.getItem("store_id");

    try {
        const themeData= await axios.get(BASE_URL+"api/get-themes?store_id="+store_id);
        return themeData;
      
        } catch (error) {
            if (axios.isCancel(error)) {
            } else {
                throw error
            }
        }

    


}

export const ProductData = async () => {
    const store_id=localStorage.getItem("store_id");
    try{

        const products = await axios.get(BASE_URL+"api/get-product?store_id="+store_id)
        return products
    }
    catch(error){
        
        if (axios.isCancel(error)) {
        } else {
            throw error
        }
    }

}

export const ZipbarConfigurationData=async ()=>{
    const store_id=localStorage.getItem("store_id");
    try{

        const ZipbarData=await axios.get(BASE_URL+"api/get-zipbarConfig?store_id="+store_id);
        return ZipbarData;
    }
    catch(error){
        console.log(error);
        if (axios.isCancel(error)) {
        } else {
            throw error
        }

    }
};

export const getDefaultJS = async () => {

    try{
        const DefaultJS = await axios.get(BASE_URL+"api/get-default-JS")
        return DefaultJS

    }catch(error){
        if (axios.isCancel(error)) {
        } else {
            throw error
        }
    }
}

export const getDefaultCSS = async () => {

    try{
        const DefaultCSS = await axios.get(BASE_URL+"api/get-default-CSS")
        return DefaultCSS

    }catch(error){
        if (axios.isCancel(error)) {
        } else {
            throw error
        }
    }
}

export const getDefaultHTML = async () => {

    try{
        const DefaultHTML = await axios.get(BASE_URL+"api/get-default-HTML")
        return DefaultHTML

    }catch(error){
        if (axios.isCancel(error)) {
        } else {
            throw error
        }

    }
}

export const getStoreDetails = async () => {

    try{
        const store_id=localStorage.getItem("store_id");
        const storeDetails = await axios.get(BASE_URL+"api/get-store-details-from-shopify?store_id="+store_id)
        
        return storeDetails
        
    }
    catch(error)
    {
        if (axios.isCancel(error)) {
        } else {
            throw error
        }
    }

}



export const getCollectionList = async () => {

    try{
        const store_id=localStorage.getItem("store_id");
        const collection = await axios.get(BASE_URL+"api/get-collections-list?store_id="+store_id)
        
        return collection
        
    }
    catch(error)
    {
        if (axios.isCancel(error)) {
        } else {
            throw error
        }
    }

}



export const getZipcodeUpdate = async () => {
    try{
        
        const store_id = localStorage.getItem("store_id");
        const zipcode = await axios.get(BASE_URL+"api/update-zipcode?store_id="+store_id)
        return zipcode

    }
    catch(error){
        if (axios.isCancel(error)){

        }else{
            throw error
        }
    }

}

export const verifyStore=async (store_id) => {
    try{
    const check= await axios.get(BASE_URL+"api/verify-store?store_id="+store_id)
    return check
    }
    catch(error)
    {
        if (axios.isCancel(error))
        {

        }
        else{
            throw error
        }
    }
}

export const planCheck=async () => {
    try{
        const store_id = localStorage.getItem("store_id");
    const check= await axios.get(BASE_URL+"api/plan-check?store_id="+store_id)
    return check;
    }
    catch(error)
    {
        if (axios.isCancel(error))
        {

        }
        else{
            throw error
        }
    }
}
