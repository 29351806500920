import React,{useState,useEffect} from 'react';
import notFound from "../assets/images/404.png";
import ErrorPage from './ErrorPage';
import {LoadImportExport} from './LoadingPage';
import ZipcodeConfigPage from './ZipcodeConfigPage'
import {ProductData, getCollectionList} from './data';

function ZipcodeConfigMiddleHook() {

    const [product, setGetProduct]=useState([]);
    const [collections, setCollection] = useState([])
    const [loading, setloading] = useState(true)
    // const [zipcode, setZipcode] = useState([])
    useEffect(()=>{
      const timer=setTimeout(()=>{
            // ProductData().then(result => setGetProduct(result.data))

            getCollectionList().then(result =>setCollection(result.data))
      },100)
            // getZipcodeUpdate().then(result => setZipcode(result.data))

            setloading(false)


        return () => {
            clearTimeout(timer)
            setGetProduct(); 
          };
    
    },[]);
   
    console.log(product)
    console.log(collections)
    const ThemeMessage=(<>Look like you haven't integrated our store .<br/>
    You can customise your filter look here after you integrate.</>);

    return (
        <div style={{height:"100%"}}>
            {collections!=null &&  collections.length!=0 && loading !=  true?
            <div style={{height:"100%"}}>
                {collections.collections.length>0
                ?<ZipcodeConfigPage product={product}  collection={collections.collections} rules={collections.rules}/>
                :<ErrorPage message={ThemeMessage} errorImage={notFound} imageWidth="600" />}
            </div>
            :<LoadImportExport/>}
        </div>
    );
}

export default ZipcodeConfigMiddleHook;