import { Layout } from '@shopify/polaris';
import { TextField } from '@shopify/polaris';
import { Form, FormLayout } from '@shopify/polaris';
import { Page } from '@shopify/polaris';
import React, { useState, useEffect } from 'react';
import {Card} from '@shopify/polaris';
import { Button, InlineError, Toast, Spinner } from '@shopify/polaris';
import { BASE_URL } from './data';
import axios from 'axios';

export default function ContactUsPage(props) {

    /**
     *     Sender Name 
     */
    const [curName,setName]=useState(props.curName);
    const handleName=((name)=>setName(name));

    /**
     *     Sender Email 
     */
    const [curEmail,setEmail]=useState(props.curEmail);
    const handleEmail=((email)=>setEmail(email));

    /**
     *     Sender Subject
     */
    const [curSubject,setSubject]=useState("I need support");
    const handleSubject=((subject)=>setSubject(subject));

    /**
     *     Sender message
     */
    const [curMessage,setMessage]=useState("");
    const handleMessage=((message)=>setMessage(message));


    useEffect(()=>{
           
            setName(props.curName)
            setEmail(props.curEmail)
        
            return () => {
                setName(); 
                setEmail();
                
              };
    

    },[props.curName, props.curEmail]);
    

    /**
    * Toast Message
    */
    const [toastData,setToastData]=useState("");
    //Success
    const [activeToast,setToast]=useState(false);
    const toggleToast=(()=>setToast(!activeToast));
    const toastSuccessMarkup=(activeToast?<Toast content={toastData} onDismiss={toggleToast} />:null);
    //Error

    const [activeErrorToast,setErrorToast]=useState(false);
    const toggleErrorToast=(()=>setErrorToast(!activeErrorToast));
    const toastErrorMarkup=(activeErrorToast?<Toast content={toastData} error onDismiss={toggleErrorToast} />:null);

    const [curLoadSend,setcurLoadSendSpinner]=useState(false);

    const spinnerMarkup=(<Button><Spinner accessibilityLabel="Email sending" size="small" /></Button>);

    const handleContectAsSubmit=((_event)=>{
        const store_id=localStorage.getItem("store_id");
        const contect_data = {'store_id' : store_id , 'store_name': curName, 'store_email': curEmail, 'subject': curSubject, 'message' : curMessage};
        setcurLoadSendSpinner(true);
        if (curName !== '' && curEmail !== '' && curEmail !== '' && curSubject !== '' && curMessage !== ''){
             
            axios({
                method:"post",
                url:BASE_URL+"api/contact-us",
                data:contect_data,
                }).then((response)=>{
                    
                    const data=response.data

                    if(data[0]==="200")
                    {
                        setToastData("Email sent Successfully.");
                        toggleToast();
                        setcurLoadSendSpinner(false);

                    }
                    
                })
            .catch((error)=>{
                    setToastData("Internal Server Error,Reload might solve problem.");
                    toggleErrorToast();
                    setcurLoadSendSpinner(false);
                })
            
        }
        else
        {
            setToastData("All fields are required.");
            toggleErrorToast();
            setcurLoadSendSpinner(false);
        }
    });
    


    return (
    <Page title="Contact us" sectioned>
        <Layout>
            <Layout.AnnotatedSection title="Support" description="We are here to help!, Send us your problem regarding our app our support team will reach you to provided Email." >
                <Card sectioned>
                    <Form onSubmit={handleContectAsSubmit}>
                        <FormLayout>
                            <div style={{display:"flex",justifyContent:"space-between"}}>
                                {/* Sender name field */}

                                <div>

                                    <TextField
                                        id="curName"
                                        label="Your Name"
                                        value={curName}
                                        onChange={handleName}
                                    />

                                    {(curName !== '')
                                        ? ''
                                        :<InlineError message="Name is required" fieldID="curName" />
                                    }
                                    
                                    {/* Sender Email field */}
                                </div>

                                <div>
                                    
                                    <TextField
                                        id="curEmail"
                                        type="email"
                                        label="Your Email"
                                        value={curEmail}
                                        onChange={handleEmail}
                                    />
                                    {(curEmail !== '')
                                        ? ''
                                        :<InlineError message="Your Email is required" fieldID="curEmail" />
                                    }

                                    
                                </div>
                            </div>
                            {/* Sender Subject field */}

                            <div>

                                <TextField
                                    id="curSubject"
                                    label="Your Subject"
                                    value={curSubject}
                                    onChange={handleSubject}
                                />
                                {(curSubject !== '')
                                    ? ''
                                    :<InlineError message="Subject is required" fieldID="curSubject" />
                                }

                                
                            </div>
                            
                            {/* Sender Subject field */}

                            <div>

                                <TextField
                                    id="curMessage"
                                    label="Message"
                                    value={curMessage}
                                    onChange={handleMessage}
                                    multiline={4}
                                />
                                {(curMessage !== '')
                                    ? ''
                                    :<InlineError message="Message is required" fieldID="curMessage" />
                                }
                                
                            </div>
                            {/* Send Button Field */}

                            <div style={{display:"flex",justifyContent:"flex-end"}}>
                               
                                {curLoadSend?spinnerMarkup:<div style={{color:"green"}}><Button submit monochrome outline>Send</Button></div>}
                            </div>


                        </FormLayout>
                    </Form>
                </Card>
            </Layout.AnnotatedSection>
        </Layout>
        {toastSuccessMarkup}
        {toastErrorMarkup}
    </Page>
    );
}

// export default ContactUsPage;   