import React from 'react';

import { Card, Page } from '@shopify/polaris';

function PrivacyPolicies(props) {
    const pageStyle={
        textStyle:{
            fontSize:"14px",
            lineHeight:"2"
        }
    }
    return (
        <Page title="Privacy polices">
            <Card sectioned>
                <p style={pageStyle.textStyle}>This Privacy Policy describes how the collected information is used and store in order to provide better user experience.</p>
            </Card>
            <Card title="Merchant information in the app" sectioned>
            <p style={pageStyle.textStyle}>We hold merchant information according to Shopify GDPR policies , 
all merchants who install our app their information is being stored to differentiate snippet data changes made by the merchant through our app Theme Customise functionality and maintain install records but once the app is uninstall after 48 hours all data of merchant has been deleted.</p>
            </Card>
            <Card title="Changes" sectioned>
            <p style={pageStyle.textStyle}>We may update this privacy policy from time to time in order to reflect, for example, changes to our practices or for other operational, legal or regulatory reasons.</p>
            </Card>
            <Card title="Contact us" sectioned>
            <p style={pageStyle.textStyle}>For more information about our privacy practices, if you have questions, or if you would like to make a complaint, please contact us by e-mail at contact@webiots.com</p>
            </Card>
        </Page>
    );
}

export default PrivacyPolicies;