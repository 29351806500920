import React,{useState,useEffect} from 'react';
import notFound from "../assets/images/404.png";
import {ZipbarConfigurationData} from './data';
import ErrorPage from './ErrorPage';
import {LoadCustomiser} from './LoadingPage';
import ZipConfigPage from './ZipConfigPage';


function ZipbarConfig() {
    const [zipbarConfigData,setZipbarConfigData]=useState();
    const [loading, setloading] = useState(true)
    useEffect(()=>{
      const timer=setTimeout(()=>{ 
      ZipbarConfigurationData().then(result=> setZipbarConfigData(result.data))
      },100);
      setloading(false)
        
        return () => {
            clearTimeout(timer);
            setZipbarConfigData(); 
            
          };

    },[]);

    const ThemeIntegrateMessage=(<>Look like you haven't integrated our application with any of your themes.<br/>
    You can customise your filter look here after you integrate.</>);

    const ThemeFetchMessage=(<>Look like we faced some problem fetching your data.<br/>
    Please contact support for more information.</>);    

    return (
        <div style={{height:"100%"}}>
            {zipbarConfigData!=null ?
            <div style={{height:"100%"}}>
                {loading != true &&zipbarConfigData.themeData!=undefined && zipbarConfigData.settingData!=undefined &&zipbarConfigData.storeDomain!=undefined   
                    ?<>
                        {zipbarConfigData.themeData.length>0
                        ?<ZipConfigPage settingData={zipbarConfigData.settingData} domainName={zipbarConfigData.storeDomain} installedTheme={zipbarConfigData.themeData} />
                        :<ErrorPage message={ThemeIntegrateMessage} errorImage={notFound} imageWidth="600" />}
                    </>
                    :<ErrorPage message={ThemeFetchMessage} errorImage={notFound} imageWidth="600" />}
            </div>
            
            :<LoadCustomiser />}
        </div>
    );
}

export default ZipbarConfig;