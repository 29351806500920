import React, { useCallback, useState } from 'react';
import { AppProvider, Frame, Navigation, TopBar } from '@shopify/polaris';
import { ArrowLeftMinor, ThemeEditMajor, ToolsMajor, FollowUpEmailMajor, ImportStoreMajor, AttachmentMajor } from '@shopify/polaris-icons';
import DocumentPage from './DocumentPage';
import ZipbarConfig from './ZipbarConfig';
import ThemeIntegrateMiddleHook from './ThemeIntegrateMiddleHook';
import ContectAsMiddleHook from './ContectAsMiddleHook';
import ZipcodeConfigMiddleHook from './ZipcodeConfigMiddleHook'

import logo from "../assets/images/zipcode filter.png";

export default function Frames(props) {


  const [mobileNavigationActive, setMobileNavigationActive] = useState(false);



  const [zipAppConfig, setZipAppConfig] = useState({ zipbarPage: false, zipcodePage: false, themeIntegratePage: true, contactUsPage: false, documentPage: false });




  const toggleMobileNavigationActive = useCallback(
    () =>
      setMobileNavigationActive(
        (mobileNavigationActive) => !mobileNavigationActive,
      ),
    [],
  );




  const topBarMarkup = (
    <TopBar
      showNavigationToggle
      onNavigationToggle={toggleMobileNavigationActive}
    />
  );

  /**
   *  Zipbar page activate
   */
  const toggleZipbar = (
    () =>
      setZipAppConfig({ zipbarPage: true, zipcodePage: false, themeIntegratePage: false, contactUsPage: false, documentPage: false })
  );


  /**
   *  Zipcode page activate
   */
  const toggleZipcode = (
    () =>
      setZipAppConfig({ zipbarPage: false, zipcodePage: true, themeIntegratePage: false, contactUsPage: false, documentPage: false })
  );

  /**
   *  Theme integrate page activate
   */
  const toggleThemeIntegrate = (
    () => setZipAppConfig({ zipbarPage: false, zipcodePage: false, themeIntegratePage: true, contactUsPage: false, documentPage: false })
  );

  /**
   *  Contact us page activate
   */
  const toggleContactUs = (
    () => {
      window.location.href = "http://pixelstrap.freshdesk.com";
      // setZipAppConfig({ zipbarPage: false, zipcodePage: false, themeIntegratePage: false, contactUsPage: true, documentPage: false })
    }
  );


  const toggleDocumentPage = (
    () => {
      setZipAppConfig({ zipbarPage: false, zipcodePage: false, themeIntegratePage: false, contactUsPage: false, documentPage: true })
    }
  );

  const backToShopify = () => {
    window.location.href = "https://" + props.domain + "/admin/apps";
  }

  const navigationMarkup = (
    <Navigation location="/">
      <Navigation.Section
        items={[
          {
            label: 'Back to Shopify',
            icon: ArrowLeftMinor,
            onClick: backToShopify,
          },
        ]}
      />

      <Navigation.Section
        separator
        title="Theme Configuration"
        items={[
          {
            url: '/',
            label: 'Theme Integration',
            icon: ToolsMajor,
            selected: zipAppConfig.themeIntegratePage,
            onClick: toggleThemeIntegrate,
          },
        ]}
      />

      <Navigation.Section
        separator
        title="App Configuration"
        items={[

          {
            url: '/',
            label: 'Theme Customiser',
            selected: zipAppConfig.zipbarPage,
            icon: ThemeEditMajor,
            disabled: false,
            onClick: toggleZipbar,
          },
          {
            url: '/',
            label: 'Import & Export',
            selected: zipAppConfig.zipcodePage,
            icon: ImportStoreMajor,
            disabled: false,
            onClick: toggleZipcode,
          },

        ]}

      />

      <Navigation.Section
        separator
        title="Help"
        items={[
          {
            url: '/',
            label: 'Contact Us',
            icon: FollowUpEmailMajor,
            selected: zipAppConfig.contactUsPage,
            onClick: toggleContactUs,
          },
          {
            url: '/',
            label: 'Document',
            icon: AttachmentMajor,
            selected: zipAppConfig.documentPage,
            onClick: toggleDocumentPage,
          }
        ]}
      />

    </Navigation>
  );


  /* Zipbar Config page */

  const zipbarConfigPage = (
    <ZipbarConfig />
  );


  /**
   *    Zipcode Config Page
   */

  const zipcodeConfigPage = (
    <div>
      <ZipcodeConfigMiddleHook />
    </div>
  );

  /**
   *    Theme integrate Page
   */


  const themeIntegratePage = (
    <div>
      <ThemeIntegrateMiddleHook />
    </div>
  )

  /**
   *    Contact us page
   */

  const contactUsPage = (
    <div>
      <ContectAsMiddleHook />
    </div>
  );

  const documentPage = (
    <div>
      <DocumentPage />
    </div>

  );

  // const loadingPageMarkup = (
  //   <SkeletonPage>
  //     <Layout>
  //       <Layout.Section>
  //         <Card sectioned>
  //           <TextContainer>
  //             <SkeletonDisplayText size="small" />
  //             <SkeletonBodyText lines={9} />
  //           </TextContainer>
  //         </Card>
  //       </Layout.Section>
  //     </Layout>
  //   </SkeletonPage>
  // );
  const zipbarPageMarkup = zipAppConfig.zipbarPage ? zipbarConfigPage : null;
  const zipcodePageMarkup = zipAppConfig.zipcodePage ? zipcodeConfigPage : null;
  const themePageMarkup = zipAppConfig.themeIntegratePage ? themeIntegratePage : null;
  const contactPageMarkup = zipAppConfig.contactUsPage ? contactUsPage : null;
  const documentPageMarkup = zipAppConfig.documentPage ? documentPage : null;

  const theme = {
    logo: {
      width: "120",
      topBarSource: logo,
    },
    colors: {
      topBar: {
        backgroundColor: "#fff"
      },
    },

  };


  return (
    <div style={{ height: '500px' }}>
      <AppProvider
        theme={theme}
        i18n={{
          Polaris: {
            Avatar: {
              label: 'Avatar',
              labelWithInitials: 'Avatar with initials {initials}',
            },
            TextField: {
              characterCount: '{count} characters',
            },
            TopBar: {
              toggleMenuLabel: 'Toggle menu',

              SearchField: {
                clearButtonLabel: 'Clear',
                search: 'Search',
              },
            },

            Frame: {

              Navigation: {
                closeMobileNavigationLabel: 'Close navigation',
              },
            },
          },
        }}
      >
        <Frame
          topBar={topBarMarkup}
          navigation={navigationMarkup}
          showMobileNavigation={mobileNavigationActive}
          onNavigationDismiss={toggleMobileNavigationActive}

        >


          {zipbarPageMarkup}
          {zipcodePageMarkup}
          {themePageMarkup}
          {contactPageMarkup}
          {documentPageMarkup}

        </Frame>
      </AppProvider>
    </div>
  );
}