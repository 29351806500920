import React,{useState,useEffect} from 'react';
import notFound from "../assets/images/404.png";
import ErrorPage from './ErrorPage';
import {LoadThemeIntegration} from './LoadingPage';
import ThemeIntegrate from './ThemeIntegrate'


import {ThemesOptionData, getDefaultCSS, getDefaultHTML, getDefaultJS,planCheck} from './data';

function ThemeIntegrateMiddleHook() {

    const [getThemesData,setThemesData]=useState();
    
    const [curHTML, setValue] = useState();
    const [curCSS, setCssValue] = useState();
    const [curJS, setJsValue] = useState();
    const [curPlan,setPlan] =useState();
    
  
    useEffect(()=>{
        const timer=setTimeout(()=>{
            ThemesOptionData().then(result=>typeof(result?.data[0])!="string"?setThemesData(result.data):null);
            getDefaultJS().then(result=> setJsValue(result.data));
            getDefaultCSS().then(result=> setCssValue(result.data));
            getDefaultHTML().then(result=>setValue(result.data));
            planCheck().then(result=>setPlan(result.data.plan))
            
        },100)
        return ()=>{
            clearTimeout(timer)
            setThemesData()
            setCssValue()
            setJsValue()
            setValue()
            setPlan()
        }

    },[]);
   

    
    const ThemeMessage=(<>Look like you haven't integrated our store .<br/>
    You can customise your filter look here after you integrate.</>);


    return (
        <div style={{height:"100%"}}>
            {getThemesData!=null && getThemesData.length !=0 &&  curHTML !=null && curCSS!=null && curJS!=null && curPlan!=null ?
            <div style={{height:"100%"}}>
                {getThemesData.length>0
                ?<ThemeIntegrate getThemesData={getThemesData} curHTML={curHTML} curCSS={curCSS} curJS={curJS}  plan={curPlan}/>
                :<ErrorPage message={ThemeMessage} errorImage={notFound} imageWidth="600" />}
            </div>
         
            :<LoadThemeIntegration />}
        </div>
    );
}

export default ThemeIntegrateMiddleHook;