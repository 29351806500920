
import './App.css';
import React, { useEffect,useState } from 'react';
import Frames from './Components/Frames';
import Login from './Components/Login';
import logo_image from './assets/images/zipcode filter.png';
import {verifyStore} from './Components/data';
import {LoadingPage} from './Components/LoadingPage';
import PrivacyPolicies from "./Components/PrivacyPolicies";

function App(props) {
  const [verify,setVerify]=useState(null);
  const [policies,setPolicies]=useState(false);

    const checkUrl=()=>{
        let query_str=window.location.search;
        
        console.log("page ",query_str.match(/privacy-policies/i))
        
        if(query_str.match(/store_id/i)=='store_id')
        {
          let request_params=query_str!=""?query_str.split("?"):[];
          if(request_params.length>0)
          {
            let store_id=request_params[1].split("=");
            if(store_id[0]==="store_id")
            {
                return store_id[1];
            }
          }
        }
        else if(query_str.match(/privacy-policies/i)=="privacy-policies")
        {
          setPolicies(true);
        }
        return false;
    };

    useEffect(()=>{
      let storeId=checkUrl()
      if(storeId!=false)
      {
          verifyStore(storeId).then(result=>{
              let re = result.data[0]
              console.log(re);
              if(re)
              {
                  setVerify(true);
                  localStorage.setItem("store_id",storeId);
                  localStorage.setItem("domain",result.data[1]);
              }
              else{
                  setVerify(false)
              }
          });
      }
      else
      {
        setVerify(false);
      }
    },[]);
    console.log(verify)
    const loading=(<LoadingPage logo={logo_image} />);
    console.log("Policies",policies)
  return (
    <>
    {verify==null
    ?loading
    :<div style={{height:"100vh"}}>
      {verify==false  ? policies?<PrivacyPolicies /> :<Login logo={logo_image} />:<Frames domain={localStorage.getItem("domain")} />}
    </div>}
    </>
  );  
}

export default App;


{/* <ErrorPage message={error_message} errorImage={notAuthorised} imageWidth="350" /> */}