import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';
import "@shopify/polaris/dist/styles.css";
import '../node_modules/bootstrap/dist/css/bootstrap.min.css'


import {AppProvider} from "@shopify/polaris";
import en from '@shopify/polaris/locales/en.json';

import "@shopify/polaris/dist/styles.css";

// ReactDOM.render(
//   // <React.StrictMode>
//   <AppProvider i18n={en}><App /></AppProvider>
//   // </React.StrictMode>,
//   document.getElementById('root')
// );

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals


ReactDOM.render(<AppProvider i18n={en}><App /></AppProvider>, document.getElementById("root"));

reportWebVitals();
