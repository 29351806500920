
import React ,{ useState} from 'react';

import { Card,Toast,Spinner} from '@shopify/polaris';

import {FormLayout } from '@shopify/polaris';

import {Button} from "@shopify/polaris";

import axios from 'axios';
import {BASE_URL} from "../data";

const FileDownload = require('js-file-download');

function ZipcodeExport(props) {
     /*
      Shopify label size 
    */ 
      const shopifyLabel={fontSize:'14px',lineHeight:'20px',fontWeight:'400'}
     
    const [toastData,setToastData]=useState("");

    //Success
    const [activeToast,setToast]= useState(false);
    const toggleToast=(()=>setToast(!activeToast));
    const toastSuccessMarkup=(activeToast?<Toast content={toastData} onDismiss={toggleToast}  />:null);
        //Error

    const [activeErrorToast,setErrorToast]=useState(false);
    const toggleErrorToast=(()=>setErrorToast(!activeErrorToast));
    const toastErrorMarkup=(activeErrorToast?<Toast content={toastData} error onDismiss={toggleErrorToast}  />:null);

    // loading for submit button
    const [curLoadInstallSpinner,setLoadInstallSpinner]=useState(false);
    const [curLoadUninstallSpinner,setLoadUninstallSpinner]=useState(false);
    const spinnerMarkup=(<Button><Spinner accessibilityLabel="Install Loading" size="small" /></Button>);



      const handelExportCSV=(() => {
        const store_id=localStorage.getItem("store_id");
        const uploadData = new FormData();
    
        uploadData.append('store_id', store_id);
        axios({
            method:"get",
            url:BASE_URL+"api/get-zipcode-csv?store_id="+store_id,
            data:uploadData,
            responseType: 'text/csv', 
        }).then((response)=>{
        
            
          
            
            if(response.data)
            {
                FileDownload(response.data, 'zipcode.csv')
                setToastData("Zipcode download Sucessfully");
                toggleToast();
                setLoadInstallSpinner(false);
                
            }
            else{
                setToastData("Resource not found, Retry again!!.");
                toggleErrorToast();
                setLoadInstallSpinner(false);
                
            }
           
        })
        .catch((error)=>{
    
            console.log(error);
            setToastData("Internal Server Error,Reload might solve problem.");
            toggleErrorToast();
            setLoadInstallSpinner(false);
           
        });
    
    
    });
    
    
    const exportPage=(
           <Card sectioned>
              <FormLayout>
                   {/* Tabs for export  */}
                    <div style={{display:"flex",justifyContent:"center"}}>
                        <Card.Section>
                            <Button plain onClick={handelExportCSV} >Download zipcode CSV</Button>
                        </Card.Section>
                    </div>
                    
               </FormLayout>
            </Card>
       );

    return (
        <div style={{padding:"15px 0px"}}>
            {exportPage}
        </div>
    );
}

export default ZipcodeExport;