import {  Layout } from '@shopify/polaris';
import React ,{ useState,useEffect} from 'react';
import {Page} from '@shopify/polaris';
import { Card,Tabs } from '@shopify/polaris';
import ZipcodeRemove from './zipcode/remove';
import ZipcodeExport from "./zipcode/export";
import ZipcodeImport from "./zipcode/import";

import {planCheck} from "./data";

export default function ZipcodeConfigPage(props) 
{
    
    
    /**
     *     Zipcode config Tabs
     */
    
    const [curZipcodeTab,setZipcodeTab]=useState(0);
    const handlezipcodeTab=((index)=>setZipcodeTab(index));
    const zipcodeTabs=[ 
    {
      id:"importZipcode",
      content:"Import",
      panelID:"importZipcodePanel"
    },
    {
        id:"updateZipcode",
        content:"Update",
        panelID:"updateZipcodePanel"
    },
    {
      id:"exportZipcode",
      content:"Export",
      panelID:"exportZipcodePanel"
    },
    
  ];
  const [plan,setPlan]=useState(null);
  useEffect(() => {
        planCheck().then(result=>{setPlan(result.data.plan)})
      return () => {
          setPlan(null)
      }
  },[]);

    return (
        
        
        
            <Page title="Import & Export"  sectioned>
            <Layout >
                <Layout.Section>
                    <Card sectioned>
                        <Tabs
                            tabs={zipcodeTabs}
                            selected={curZipcodeTab}
                            onSelect={handlezipcodeTab}
                        >
                        </Tabs>
                        
                    </Card>
                    {plan!=null?<>{curZipcodeTab===0?<ZipcodeImport plan={plan} collection={props.collection} rules={props.rules} />: curZipcodeTab===1?<ZipcodeRemove collection={props.collection} />:<ZipcodeExport />}</>:null}
                </Layout.Section>
            </Layout>

            
        </Page>
        
            
    );
}